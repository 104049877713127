import {Swiper, SwiperSlide} from 'swiper/react';
import '../../Home/Swipper/swippercss.css'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/free-mode'/*
import {FreeMode, Pagination} from 'swiper/modules';
import {Autoplay, Navigation} from "swiper/types/modules"*/

import 'swiper/css/navigation';

import {Autoplay, Navigation} from 'swiper/modules';
import {FreeMode, Pagination} from 'swiper/modules';


export function MenuCardSwipper() {
    const brand = [

        {
            id: "03",
            image: "m7.png",

        },
        {
            id: "03",
            image: "m8.png",

        },
        {
            id: "01",
            image: "m1.jpeg",

        },
        {
            id: "02",
            image: "m2.jpeg",

        },





        {
            id: "03",
            image: "m3.png",

        },
        {
            id: "03",
            image: "m4.png",

        },

        {
            id: "01",
            image: "Menu Card cover1.png",

        },
        {
            id: "02",
            image: "Menu Card cover2.png",

        },

        {
            id: "03",
            image: "m5.png",

        }, {
            id: "03",
            image: "m6.png",

        },


    ]
    return (
        <div className='flex mt-16  '>
            <Swiper
                modules={[Autoplay, Navigation, Pagination, FreeMode]} // Add modules as options

                autoplay={{delay: 3000}} // Autoplay with a 3-second delay


                breakpoints={{
                    340: {slidesPerView: 3, spaceBetween: 15},
                    700: {slidesPerView: 4, spaceBetween: 18},
                    1200: { slidesPerView: 3, spaceBetween: 5 },
                }}
                freeMode={true}
                pagination={{
                    clickable: true,

                }}

                className='max-w-[100%] /*lg:max-w-[100%]*/ '
            >
                {brand.map((brand, index) => (
                    <SwiperSlide className='' key={index}>
                        <div
                            className='flex rounded justify-center items-center flex-col  h-[90%] {/*lg:h-[88%]*/} w-[98%]  {/*lg:mt-6*/} mb-12 {/*lg:mb-20*/}   '
                        >
                            <div className='w-[90%] xl:w-[70%] md:w-[100%] {/*lg:w-[54%]*/}'>
                                <img src={require(`../../../../image/Loopy web/${brand.image}`)}
                                     alt=''/>
                            </div>

                        </div>
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    );
}
