import {Swiper, SwiperSlide} from 'swiper/react';
import '../../Home/Swipper/swippercss.css'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/free-mode'/*
import {FreeMode, Pagination} from 'swiper/modules';
import {Autoplay, Navigation} from "swiper/types/modules"*/

import 'swiper/css/navigation';

import {Autoplay,  Navigation} from 'swiper/modules';
import {FreeMode, Pagination} from 'swiper/modules';


export function SwipperBrand() {
    const brand = [
        {
            id: "01",
            image: "DSI.png",
            heading: 'DSI',
            pharagh: 'Welcome to DSI Footcandy, where you\'re the star of our fashion\n' +
                'story! As Sri Lanka\'s premier online fashion destination, we offer a\n' +
                'delightful shopping experience right at your fingertips. Explore a vast\n' +
                'collection of global and local brands for men, women, boys, and girls,\n' +
                'all curated to suit every style and occasion. Join us in embracing the\n' +
                'joy of fashion!'
        },
        {
            id: "02",
            image: "inzee1.png",
            heading: 'Insee Cement',
            pharagh: '"Welcome to INSEE Cement Sri Lanka, the nation\'s forefront in\n' +
                'integrated cement manufacturing. Our innovative solutions and\n' +
                'commitment to sustainability shape Sri Lanka\'s skyline. With a robust\n' +
                'local capacity and a dedicated team, we lead the construction\n' +
                'industry, offering high-quality products and pioneering\n' +
                'advancements, ensuring a future-ready built environment.'

        },
        {
            id: "03",
            image: "slt.png",
            heading: 'SLT',
            pharagh: 'Experience the future with SLT-MOBITEL, Sri Lanka\'s leading ICT\n' +
                'solutions provider with a rich history of over 163 years. From\n' +
                'pioneering mobile technologies to delivering seamless connectivity,\n' +
                'we\'re committed to shaping digital lifestyles. Recognized for\n' +
                'innovation and reliability, we connect communities, empower\n' +
                'businesses, and lead the nation towards a connected tomorrow.'
        }, {
            id: "03",
            image: "Fazal.png",
            heading: 'Fazaal Hardware',
            pharagh: 'Elevate your spaces with Fazaal Hardware, the trusted name in\n' +
                'quality sanitary ware since 1967. As importers and distributors of\n' +
                'premium tiles and sanitary wares, we bring elegance and functionality\n' +
                'to your homes and businesses. Discover timeless designs and superior\n' +
                'craftsmanship for a truly remarkable experience.'

        }, {
            id: "03",
            image: "fazalTwo.png",
            heading: 'Fazaal Tools',
            pharagh: 'Unlock your potential with Fazaal Tools, Galle\'s premier destination\n' +
                'for power and hand tools. As the top importer in the region, we offer a\n' +
                'curated selection of premium tools at unbeatable prices. From\n' +
                'precision power tools to reliable hand tools, elevate your craft with\n' +
                'Fazaal Tools – where quality meets affordability.'
        },
        {
            id: "03",
            image: "Gamunu.png",
            heading: 'Gemunu Coir Products',
            pharagh: 'At Gemunu Coir Products, we\'re dedicated to crafting superior coir\n' +
                'products using sustainable coconut tree materials. Our commitment\n' +
                'to quality ensures each product meets the highest standards.\n' +
                'Experience the natural beauty and durability of our coir creations, as\n' +
                'we harmonize tradition with eco-conscious innovation in Sri Lanka.'
        },
        {
            id: "03",
            image: "JMC.PNG",
            heading: 'JMC Group',
            pharagh: 'Welcome to JMC Group, your trusted partner in construction and\n' +
                'engineering material production and services in Sri Lanka. With a\n' +
                'commitment to excellence, we deliver innovative solutions tailored to\n' +
                'your project needs. Experience reliability, quality, and expertise with\n' +
                'JMC Group as we build together for a brighter future.'
        },
        {
            id: "03",
            image: "MEnterPrises.png",
            heading: 'M Enterpricess',
            pharagh: 'Discover excellence with M.Enterprises, Sri Lanka\'s premier provider\n' +
                'of construction and engineering material production and services.\n' +
                'From cutting-edge innovations to reliable solutions, we\'re committed\n' +
                'to elevating every project. Experience quality craftsmanship and\n' +
                'unparalleled expertise with M.Enterprises as we pave the way for a\n' +
                'stronger, more sustainable future.'
        },
        {
            id: "03",
            image: "Madeena (2).png",
            heading: 'Madeena',
            pharagh: 'Indulge in a culinary journey at MADEENA Beach Hotel, Galle, Sri\n' +
                'Lanka, where we offer healthy and quality foods at the best prices.\n' +
                'Savor delicious dishes crafted with care, while enjoying the scenic\n' +
                'beauty of the coast. Experience culinary excellence and affordability\n' +
                'combined, only at MADEENA Beach Hotel.'
        },
        {
            id: "03",
            image: "Mr apple Logo (1).png",
            heading: 'Mr apple',
            pharagh: 'Discover the ultimate Apple experience at Mr.Apple in Galle, Sri\n' +
                'Lanka. Your one-stop destination for genuine Apple products with\n' +
                'warranty assurance. From iPhones to MacBooks, find the latest\n' +
                'innovations under one roof. Trust in quality, reliability, and expert\n' +
                'service – only at Mr.Apple.'
        },
        {
            id: "03",
            image: "noBody-removebg-preview.png",
            heading: 'Noody Tribes',
            pharagh: 'Where Melbourne meets Sri Lanka in a vibrant fusion of\n' +
                'oversized chic, statement jewelry, and urban beats. Embrace your\n' +
                'bold side with our liberating fashion ethos, celebrating street culture\'s\n' +
                'fluidity. It\'s more than just clothes; it\'s your journey to confidence and\n' +
                'authenticity. Be open-minded, be bold, be you.'
        },

        {
            id: "03",
            image: "sand.png",
            heading: 'Sand Beach',
            pharagh: 'Experience paradise at Sand Beach, nestled along the stunning\n' +
                'Unawatuna Beach in Galle, Sri Lanka. Our enchanting hotel resort\n' +
                'offers the perfect blend of luxury and tranquility. Indulge in gourmet\n' +
                'dining and blissful accommodations, surrounded by the beauty of\n' +
                'Unawatuna Beach. Escape to serenity at Sand Beach.'
        },

        {
            id: "03",
            image: "pcguide_logo_m.png",
            heading: 'PC guide',
            pharagh: 'At pcguide.lk, we\'re the heartbeat of Sri Lanka\'s computer market. As\n' +
                'your ultimate destination for all things tech, we cater to users of all\n' +
                'ages and needs. From expert advice to cutting-edge products, we\'re\n' +
                'your trusted guide through the ever-evolving world of technology.'
        },


        {
            id: "03",
            image: "Serendib.png",
            heading: 'Serendib',
            pharagh: 'Welcome to Serendib, your window to the vibrant tapestry of Sri\n' +
                'Lankan culture and beyond. As a dynamic news and media platform,\n' +
                'we showcase the rich heritage of Sri Lanka while providing valuable\n' +
                'insights to a global audience. Explore, engage, and embrace the\n' +
                'essence of Serendib.'
        },
        {
            id: "03",
            image: "Sumith.png",
            heading: 'Sumith`s Signature',
            pharagh: 'Sumith\'s Signature: Where tradition meets innovation, crafting\n' +
                'timeless elegance for the modern gentleman. With decades of\n' +
                'expertise in fine craftsmanship and personalized service, we redefine\n' +
                'men\'s style with unparalleled quality and attention to detail.\n' +
                'Experience sartorial excellence and exude confidence in every\n' +
                'Sumith\'s Signature suit, where sophistication meets artistry.'
        },



        ]
    return (
        <div className='flex ml-[20px]   '>
            <Swiper
                modules={[Autoplay, Navigation,Pagination,FreeMode]} // Add modules as options


                autoplay={{ delay: 3000 }} // Autoplay with a 3-second delay



                breakpoints={{
                    340: { slidesPerView: 1, spaceBetween: 15 },
                    700: { slidesPerView: 2, spaceBetween: 5 },
                    1200: { slidesPerView: 3, spaceBetween: 5 },
                }}
                freeMode={true}
                pagination={{
                    clickable: true,

                }}

                className='max-w-[100%] /*lg:max-w-[80%]*/ '
            >
                {brand.map((brand, index) => (
                    <SwiperSlide key={index}>
                        <div className='flex rounded justify-center items-center flex-col xl:mb-[226px]
                        h-[86%]  w-[90%] mt-5  mr-1 ml-1 mb-[83px] '
                             style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>

                            <div className="h-[30%] flex items-center justify-center">
                                <div className='w-[159px] h-[96px] flex justify-center items-center xl:mt-[-30px]'>
                                    <img src={require(`../../../../image/Loopy web/${brand.image}`)}
                                         alt=''/>
                                </div>
                            </div>


                            <div className="h-[70%] flex justify-center items-start">
                                <div className='flex flex-col items-center justify-center w-10/12 lg:[92.3%]'>
                                    <h2 className='font-semibold'>{brand.heading}</h2>
                                    <p className=' text-[13px] text-justify mt-1 xl:leading-[30px] xl:mt-[50px]'>
                                        {brand.pharagh}
                                    </p>
                                </div>
                            </div>

                        </div>
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    );
}
