import Design from "../../../image/Loopy web/designCover.jpg";
import archi from "../../../image/Loopy web/Card.png";
import archi2 from "../../../image/Loopy web/business_new_01.jpg";
import archi1 from "../../../image/Loopy web/card 3.jpg";
import archi3 from "../../../image/Loopy web/card 4.jpg";
import label1 from "../../../image/Loopy web/lable_new.jpg";
import label2 from "../../../image/Loopy web/lable 2.png";
import label3 from "../../../image/Loopy web/lable 3.png";
import label4 from "../../../image/Loopy web/lable 4.png";
import asp1 from "../../../image/Loopy web/Book_cover_new_loopy.png";
import asp3 from "../../../image/Loopy web/Calender new.png";
import asp2 from "../../../image/Loopy web/diary_New.png";
import asp4 from "../../../image/Loopy web/X_banner_New_Loopy.png";
import gallery9 from "../../../image/Loopy web/logo4.jpg";
import gallery8 from "../../../image/Loopy web/logo1.jpg";
import gallery6 from "../../../image/Loopy web/logo3.jpg";
import gallery5 from "../../../image/Loopy web/log5.jpeg";
import gallery4 from "../../../image/Loopy web/log6.jpeg";
import gallery3 from "../../../image/Loopy web/logo7.jpeg";
import gallery2 from "../../../image/Loopy web/logo8.jpeg";
import gallery1 from "../../../image/Loopy web/logo9.jpeg";


import hoading1 from '../../../image/Loopy web/hoarding_new.png'
import hoading2 from '../../../image/Loopy web/holdingmenu.png'


import style from "../Designing/Designing.module.css"
import {SocialMenu} from "./SocialSwipper/SocialMenu";
import {SocialMediaSwipper} from "./SocialSwipper/SocialMediaSwipper";
import {SocialEndSwippwe} from "./SocialSwipper/SocialEndSwippwe";

export function SocialMedia() {
    return (
        <section className='font-poppins'>
            {/*start of main*/}
            <section>
                <div className={style.bg}
                     style={{backgroundImage: `url(${Design})`}}>
                    <div className='absolute top-1/2 left-0 right-0 transform -translate-y-1/2 lg:top-[300px] lg:text-[500px]'>

                        <h1 className={style.bgTitle}>
                            Social Media
                        </h1>
                    </div>

                </div>
            </section>
            {/*End of main*/}

            {/* start business card design*/}
            <section className={style.mainSection}>
                {/*main div*/}
                <div className={style.ParaDiv}>
                    <div className='flex flex-col justify-center items-center '>
                        <h2 className={style.colourText}>COLOURFUL PHOTOGRAPHY</h2>
                        {/*description*/}
                        <h1 className={style.businessText}>Business Card Designing</h1>
                        <p className={style.businessPara}>
                            Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a laudantium
                            internos. Non quis eius quo eligendi corrupti et fugiat nulla qui soluta recusandae in
                            maxime quasi aut ducimus illum aut optio quibusdam!Et labore harum non nobis ipsum eum
                            molestias mollitia et corporis praesentium a laudantium internos. Non quis eius quo eligendi
                            corrupti et fugiat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                            quibusdam!
                        </p>
                    </div>
                    {/*photo*/}
                    <div className={style.designPhoto}>
                        <div className={style.cardPhoto}>
                            <img src={archi} alt='' className={style.cardImage}/>
                            <img src={archi2} alt='' className={style.cardImage2}/>
                        </div>
                        <div className={style.cardPhoto}>
                            <img src={archi1} alt='' className={style.cardImage}/>
                            <img src={archi3} alt='' className={style.cardImage2}/>

                        </div>

                    </div>

                </div>
            </section>
            {/*end business card design*/}

            {/*start with lable design*/}
            <section className='mt-section_Space'>
                <div className={style.ParaDiv}>
                    <div className={style.designMainDiv}>
                        <h2 className={style.colourText}>colourful Labels</h2>
                        <h1 className={style.businessText}>Label Designing</h1>
                        <p className={style.businessPara}>
                            Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a laudantium
                            internos. Non quis eius quo eligendi corrupti et fugiat nulla qui soluta recusandae in
                            maxime quasi aut ducimus illum aut optio quibusdam!Et labore harum non nobis ipsum eum
                            molestias mollitia et corporis praesentium a laudantium internos. Non quis eius quo eligendi
                            corrupti et fugiat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                            quibusdam!
                        </p>
                    </div>

                    <div className={style.designPhoto}>
                        <div className={style.cardPhoto}>
                            <img src={label1} alt='' className={style.cardImage}/>
                            <img src={label2} alt='' className={style.cardImage2}/>
                        </div>
                        <div className={style.cardPhoto}>
                            <img src={label3} alt='' className={style.cardImage}/>
                            <img src={label4} alt='' className={style.cardImage2}/>

                        </div>

                    </div>

                </div>

            </section>
            {/*end with lable design*/}

            {/* start of requirement setting*/}
            <section className='mt-section_Space'>
                <div className={style.ParaDiv}>
                    <div className={style.designMainDiv}>
                        <h2 className={style.colourText}>COLOURFUL DESIGNS</h2>
                        <h1 className={style.businessText}>Other Required Designing</h1>
                        <p className={style.businessPara}>
                            Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a laudantium
                            internos. Non quis eius quo eligendi corrupti et fugiat nulla qui soluta recusandae in
                            maxime quasi aut ducimus illum aut optio quibusdam!Et labore harum non nobis ipsum eum
                            molestias mollitia et corporis praesentium a laudantium internos. Non quis eius quo eligendi
                            corrupti et fugiat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                            quibusdam!
                        </p>
                    </div>

                    <div className='flex flex-wrap w-full justify-center items-center mt-[0rem] gap-4 xl:mt-[-3rem]'>

                        {/*Book*/}
                        <div className={style.itemDetails}>
                            <div className="lg:w-1/2 lg:justify-center lg:items-center">
                                <img src={asp1} alt='' className='w-[100%] xl:h-[35rem] xl:w-[38rem]'/>
                            </div>
                            <div className=' flex xl:w-1/2 xl:mr2'>
                                <div className={style.itemMainDiv}>
                                    <h1 className={[style.businessText, style.businessTextLg].join(' ')}>Book Cover
                                        Designings</h1>
                                    <p className={[style.businessPara, style.businessParaLg].join(' ')}>
                                        orem Ipsum is simply dummy text of the printing and typesetting industry
                                        . Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type
                                        specimen book. It has survived not only five centuries, but also the leap into
                                        electronic typesetting, remaining essentially unchanged. It was popularised
                                        in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                        passages,
                                        and more recently with desktop
                                        publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                    <div className={style.itemDetailsButton}>
                                        <button
                                            className={[style.viewButton, style.viewButtonLg].join(' ')}>
                                            View More
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/*Calender*/}
                        <div className={[style.itemDetails2, style.itemDetailsLg].join(' ')}>
                            <div>
                                <img src={asp3} alt='' className='w-[100%]'/>
                            </div>
                            <div className=' flex xl:w-1/2'>
                                <div className={style.itemMainDiv}>
                                    <h1 className={[style.businessText, style.businessTextLg2].join(' ')}>Calender
                                        Designs</h1>
                                    <p className={[style.businessPara, style.businessParaLg].join(' ')}>
                                        orem Ipsum is simply dummy text of the printing and typesetting industry
                                        . Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type
                                        specimen book. It has survived not only five centuries, but also the leap into
                                        electronic typesetting, remaining essentially unchanged. It was popularised
                                        in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                        passages,
                                        and more recently with desktop
                                        publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                    <button
                                        className={[style.viewButton, style.viewButtonLg2].join(' ')}>
                                        View More
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/*Diary*/}
                        <div className={style.itemDetails}>
                            <div className="lg:w-1/2 lg:justify-center lg:items-center">
                                <img src={asp2} alt='' className='w-[100%] lg:h-[35rem] lg:w-[38rem]'/>
                            </div>
                            <div className=' flex lg:w-1/2 lg:mr2'>
                                <div className={style.itemMainDiv}>
                                    <h1 className={[style.businessText, style.businessTextLg,style.diarySpecial].join(' ')}>Diary
                                        Designing</h1>
                                    <p className={[style.businessPara, style.businessParaLg].join(' ')}>
                                        orem Ipsum is simply dummy text of the printing and typesetting industry
                                        . Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type
                                        specimen book. It has survived not only five centuries, but also the leap into
                                        electronic typesetting, remaining essentially unchanged. It was popularised
                                        in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                        passages,
                                        and more recently with desktop
                                        publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                    <div className={style.itemDetailsButton}>
                                        <button
                                            className={[style.viewButton, style.viewButtonLg].join(' ')}>
                                            View More
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* X banner */}
                        <div className={[style.itemDetails2, style.itemDetailsLg].join(' ')}>
                            <div>
                                <img src={asp4} alt='' className='w-[100%] xl:w-[502px]'/>
                            </div>
                            <div className=' flex xl:w-1/2'>
                                <div className={style.itemMainDiv}>
                                    <h1 className={[style.businessText, style.businessTextLg2].join(' ')}>X banner
                                        Designs</h1>
                                    <p className={[style.businessPara, style.businessParaLg].join(' ')}>
                                        orem Ipsum is simply dummy text of the printing and typesetting industry
                                        . Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type
                                        specimen book. It has survived not only five centuries, but also the leap into
                                        electronic typesetting, remaining essentially unchanged. It was popularised
                                        in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                        passages,
                                        and more recently with desktop
                                        publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                    <button
                                        className={[style.viewButton, style.viewButtonLg2].join(' ')}>
                                        View More
                                    </button>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>
            </section>
            {/* end of requirement setting*/}

            {/*start of t-shirt*/}
            <section>

                <div className={style.TshirtDesign}>
                    <div className={style.tShirtMain}>
                        <h2 className={style.colourText}>COLOURFUL DESIGNING</h2>
                        <h1 className={style.businessText}>T shirt designing</h1>
                        <p className={style.businessPara}>
                            Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a laudantium
                            internos. Non quis eius quo eligendi corrupti et fugiat nulla qui soluta recusandae in
                            maxime quasi aut ducimus illum aut optio quibusdam!Et labore harum non nobis ipsum eum
                            molestias mollitia et corporis praesentium a laudantium internos. Non quis eius quo eligendi
                            corrupti et fugiat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                            quibusdam!
                        </p>
                    </div>
                    <SocialMediaSwipper/>

                </div>
            </section>
            {/*end of t-shirt*/}

            {/*Start of log design*/}
            <section className='mt-section_Space'>
                <div className='mr-[2rem] ml-[2rem]'>
                    <h1 className={style.businessText}>Logo
                        Designing</h1>

                    <div className='w-full grid grid-cols-3 grid-rows-2  mt-12 gap-2 '>

                        <div className='bg-cover bg-center col-span-1 '>
                            <img src={gallery6}
                                 className={style.logoImage}/>
                        </div>

                        <div className='bg-cover bg-center col-span-1 '>
                            <img src={gallery5}
                                 className={style.logoImage}/>
                        </div>
                        <div className='bg-cover bg-center col-span-1 '>
                            <img src={gallery4}
                                 className={style.logoImage}/>
                        </div>
                        <div className='bg-cover bg-center col-span-1 '>
                            <img src={gallery8}
                                 className={style.logoImage}/>
                        </div>
                        <div className='bg-cover bg-center col-span-1 '>
                            <img src={gallery9}
                                 className={style.logoImage}/>
                        </div>

                        <div className='bg-cover bg-center col-span-1 '>
                            <img src={gallery3}
                                 className={style.logoImage}/>
                        </div>
                        <div className='bg-cover bg-center col-span-1 '>
                            <img src={gallery2}
                                 className={style.logoImage}/>
                        </div>
                        <div className='bg-cover bg-center col-span-1 '>
                            <img src={gallery3}
                                 className={style.logoImage}/>
                        </div>
                        <div className='bg-cover bg-center col-span-1 '>
                            <img src={gallery9}
                                 className={style.logoImage}/>
                        </div>
                    </div>
                </div>
            </section>
            {/*end of log design*/}

            {/*start of explore more*/}
            <section className='mt-section_Space'>
                <div className={style.brocher}>
                    <div className='flex flex-col justify-center items-center '>
                        <h2 className={style.colourText}>EXPLORE MORE</h2>
                        {/*description*/}
                        <h1 className={style.businessText}>Brochure, Postcard, Flyer Designing</h1>
                        <p className={style.businessPara}>
                            Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a laudantium
                            internos. Non quis eius quo eligendi corrupti et fugiat nulla qui soluta recusandae in
                            maxime quasi aut ducimus illum aut optio quibusdam!Et labore harum non nobis ipsum eum
                            molestias mollitia et corporis praesentium a laudantium internos. Non quis eius quo eligendi
                            corrupti et fugiat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                            quibusdam!
                        </p>
                    </div>

                    {/*  Descriptions one*/}
                    <div className={style.descriptionMainDesigns}>
                        <div className='flex flex-col  lg:w-1/2 {/*lg:m-[40px]*/}'>
                            <div className='flex text-left flex-col '>
                                <h2 className={style.colourText}>TREND</h2>
                                <h1 className={style.businessText}>Brochure Designing</h1>
                                <p className={style.businessPara}>
                                    Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a
                                    laudantium internos. Non quis eius quo eligendi corrupti et fu
                                    giat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                                    quibusdam!Et labore harum non nobis ipsum eum molestias mollitia
                                    et corporis praesentium a laudantium internos. Non quis eius quo eligendi corrupti
                                    et fugiat nulla qui soluta recusandae i
                                    giat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                                    quibusdam!Et labore harum non nobis ipsum eum molestias mollitia
                                    et corporis praesentium a laudantium internos. Non quis eius quo eligendi corrupti
                                    et fugiat nulla qui soluta recusandae i
                                    n maxime quasi aut ducimus illum aut optio quibusdam!!
                                </p>
                            </div>

                        </div>
                        {/************************************/}
                        <div className='flex gap-4 lg:w-1/2'>
                            <div className={style.designItems}>
                                <img src={archi} alt='' className={style.itemPhotoRowOne}/>
                                <img src={archi2} alt='' className={style.itemPhotoRowTwo}/>
                            </div>
                            <div className={style.designItems}>
                                <img src={archi1} alt='' className={style.itemPhotoRowOne}/>
                                <img src={archi3} alt='' className={style.itemPhotoRowTwo}/>

                            </div>
                        </div>
                    </div>

                    {/*Description two*/}
                    <div className={[style.descriptionMainDesigns,style.testLg].join(' ')}>
                        <div className={style.itemMainTwo}>
                            <div className={style.postCardDiv}>
                                <h2 className={style.colourText}>TREND</h2>
                                <h1 className={style.businessText}>Postcard Designing</h1>
                                <p className={style.businessPara}>
                                    Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a
                                    laudantium internos. Non quis eius quo eligendi corrupti et fu
                                    giat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                                    quibusdam!Et labore harum non nobis ipsum eum molestias mollitia
                                    et corporis praesentium a laudantium internos. Non quis eius quo eligendi corrupti
                                    et fugiat nulla qui soluta recusandae i
                                    giat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                                    quibusdam!Et labore harum non nobis ipsum eum molestias mollitia
                                    et corporis praesentium a laudantium internos. Non quis eius quo eligendi corrupti
                                    et fugiat nulla qui soluta recusandae i
                                    n maxime quasi aut ducimus illum aut optio quibusdam!!
                                </p>
                            </div>

                        </div>
                        {/************************************/}
                        <div className='flex gap-4 lg:m-[40px]'>
                            <div className={style.designItems}>
                                <img src={archi} alt='' className={style.itemPhotoRowOne}/>
                                <img src={archi2} alt=''  className={style.itemPhotoRowTwo}/>
                            </div>
                            <div className={style.designItems}>
                                <img src={archi1} alt='' className={style.itemPhotoRowOne}/>
                                <img src={archi3} alt=''  className={style.itemPhotoRowTwo}/>

                            </div>
                        </div>
                    </div>

                    {/* Description three*/}
                    <div className={style.descriptionMainDesigns}>
                        <div className={style.itemMainTwo}>
                            <div className={style.flyerDesign}>
                                <h2 className={style.colourText}>TREND</h2>
                                <h1 className={style.businessText}>Flyer Designing</h1>
                                <p className={style.businessPara}>
                                    Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a
                                    laudantium internos. Non quis eius quo eligendi corrupti et fu
                                    giat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                                    quibusdam!Et labore harum non nobis ipsum eum molestias mollitia
                                    et corporis praesentium a laudantium internos. Non quis eius quo eligendi corrupti
                                    et fugiat nulla qui soluta recusandae i
                                    giat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                                    quibusdam!Et labore harum non nobis ipsum eum molestias mollitia
                                    et corporis praesentium a laudantium internos. Non quis eius quo eligendi corrupti
                                    et fugiat nulla qui soluta recusandae i
                                    n maxime quasi aut ducimus illum aut optio quibusdam!!
                                </p>
                            </div>

                        </div>
                        {/************************************/}
                        <div className='flex gap-4 lg:m-[40px]'>
                            <div className={style.designItems}>
                                <img src={archi} alt='' className={style.itemPhotoRowOne}/>
                                <img src={archi2} alt='' className={style.itemPhotoRowTwo}/>
                            </div>
                            <div className={style.designItems}>
                                <img src={archi1} alt='' className={style.itemPhotoRowOne}/>
                                <img src={archi3} alt='' className={style.itemPhotoRowTwo}/>

                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col'>

                        {/* hoarding 1*/}
                        <div className={style.hoardingDesign}>
                            <div className='flex '>
                                {/*<div className={style.hordingImageDiv}>
                                    <img src={hoading1} alt='' className={[style.hoardingImage,style.hoardingLeftLg].join(' ')}/>
                                </div>*/}
                                <div className={[style.hordingImageDiv,style.hoardingDownMd].join(' ')}>
                                    <img src={hoading1} alt='' className={[style.hoardingImage,style.hoardingUpLg].join(' ')}/>
                                </div>


                            </div>
                            {/************************************/}
                            <div className={style.hoardingDesignMain}>
                                <div className={style.itemMainTwo}>
                                    <h2 className={style.colourText}>EXPLORE MORE</h2>
                                    <h1 className={style.businessText}>Hoarding Designing</h1>
                                    <p className={style.businessPara}>
                                        Et labore harum non nobis ipsum eum molestias mollitia e
                                        Et labore harum non nobis ipsum eum molestias mollitia e
                                        t corporis praesentium a laudantium recusandae i
                                        n maxime quasi aut ducimus illum aut optio quibusdam!t corporis praesentium a
                                        laudantium recusandae i
                                        n maxime quasi aut ducimus illum aut optio quibusdam!
                                    </p>
                                </div>

                            </div>

                        </div>

                        {/* hoarding 2*/}
                        <div className='flex w-full  '>
                            <div className={[style.hoardingDesign2Div,style.menuUpLg].join(' ')}>
                                <div className={style.menuDesignDiv}>
                                    <h2 className={style.colourText}>EXPLORE MORE</h2>
                                    <h1 className={style.businessText}>Menu designing</h1>
                                    <p className={style.businessPara}>
                                        Et labore harum non nobis ipsum eum molestias mollitia e
                                        Et labore harum non nobis ipsum eum molestias mollitia e
                                        t corporis praesentium a laudantium recusandae i
                                        n maxime quasi aut ducimus illum aut optio quibusdam!t corporis praesentium a
                                        laudantium recusandae i
                                        n maxime quasi aut ducimus illum aut optio quibusdam!
                                    </p>
                                </div>

                            </div>
                            <div className={style.hoardingDesign2}>
                                <div className='w-full flex-col h-[25rem] '>
                                    <img src={hoading2} alt='' className='w-[120%] h-full'/>
                                </div>

                            </div>

                        </div>

                        {/*menu card Swipper*/}
                        <SocialMenu/>
                    </div>


                </div>
            </section>
            {/*end of explore more*/}

            <section className='mt-section_Space'>
                <div className='flex flex-col ml-12 mr-12'>
                    <div className={style.invitationDiv}>
                        <h2 className={style.colourText}>EXPLORE MORE</h2>
                        {/*description*/}
                        <h1 className={style.businessText}>Invitation Designing</h1>
                        <p className={style.businessPara}>
                            Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a laudantium
                            internos. Non quis eius quo eligendi corrupti et fugiat nulla qui soluta recusandae in
                            maxime quasi aut ducimus illum aut optio quibusdam!Et labore harum non nobis ipsum eum
                            molestias mollitia et corporis praesentium a laudantium internos. Non quis eius quo eligendi
                            corrupti et fugiat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                            quibusdam!
                        </p>
                    </div>
                    <SocialEndSwippwe/>

                </div>
            </section>

        </section>
    );
}
