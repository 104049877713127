import Design from "../../../image/Loopy web/designCover.jpg";
import b1 from "../../../image/Loopy web/businessNew.jpg";
import b2 from "../../../image/Loopy web/b2.jpg";
import b3 from "../../../image/Loopy web/visiting1.png";
import b4 from "../../../image/Loopy web/green-new.jpg";
import label1 from "../../../image/Loopy web/LableNew1.png";
import label2 from "../../../image/Loopy web/LableNew2.jpg";
import label3 from "../../../image/Loopy web/LableNew4.jpg";
import label4 from "../../../image/Loopy web/l6.png";
import asp1 from "../../../image/Loopy web/Book_cover_new_loopy.png";
import asp3 from "../../../image/Loopy web/calculatorNew.png";
import asp2 from "../../../image/Loopy web/diary_New.png";
import asp4 from "../../../image/Loopy web/X_banner_New_Loopy.png";
import {TShirtSwipper} from "./DesignSwipper/TShirtSwipper";
import tharushaHotel from "../../../image/Loopy web/Tharusha Hotel .png";
import tharushaTuvers from "../../../image/Loopy web/Tharusha tours .png";
import saffron from "../../../image/Loopy web/saffron logo 1.png";
import sandBeach from "../../../image/Loopy web/sandBeach.png";
import awalokana from "../../../image/Loopy web/Awalokana (2).png";
import yasi from "../../../image/Loopy web/Yasi.png";
import VOH from "../../../image/Loopy web/VOH LOGO.png";
import gamunu from "../../../image/Loopy web/gc.png";
import taavetti from "../../../image/Loopy web/Taavetti.png";
import br1 from "../../../image/Loopy web/br1.jpg";
import br2 from "../../../image/Loopy web/br2.jpg";
import br3 from "../../../image/Loopy web/br3.png";
import br4 from "../../../image/Loopy web/br4.jpg";
import f1 from "../../../image/Loopy web/MrApple.jpg";
import f2 from "../../../image/Loopy web/fazaalNew.png";
import f3 from "../../../image/Loopy web/sappyyyy.jpg";
import f4 from "../../../image/Loopy web/madeenaNew.png";
import gallery1 from "../../../image/Loopy web/logo9.jpeg";


import hoading1 from '../../../image/Loopy web/hoarding_new.png'
import hoading2 from '../../../image/Loopy web/holdingmenu.png'
import {MenuCardSwipper} from "./DesignSwipper/MenuCardSwipper";
import {InvitatipnSwipper} from "./DesignSwipper/InvitatipnSwipper";

import style from "../Designing/Designing.module.css"

export function Designing() {
    return (
        <section className='font-poppins'>
            {/*start of main*/}
            <section>
                <div className={style.bg}
                     style={{backgroundImage: `url(${Design})`}}>
                    <div className='absolute top-1/2 left-0 right-0 transform -translate-y-1/2 lg:top-[300px] lg:text-[500px]'>

                        <h1 className={style.bgTitle}>
                            Designing
                        </h1>
                    </div>

                </div>
            </section>
            {/*End of main*/}

            {/* start business card design*/}
            <section className={style.mainSection}>
                {/*main div*/}
                <div className={style.ParaDiv}>
                    <div className='flex flex-col justify-center items-center '>
                        <h2 className={style.colourText}>COLOURFUL PHOTOGRAPHY</h2>
                        {/*description*/}
                        <h1 className={style.businessText}>Business Card Designing</h1>
                        <p className={[style.businessPara,style.businessTitle,style.businessParaNew].join(' ')}>
                            Forget flimsy rectangles with basic info. We design business cards that
                            are conversation starters, not afterthoughts. They'll showcase your
                            brand personality, leave a lasting impression, and spark meaningful
                            connections
                        </p>
                    </div>
                    {/*photo*/}
                    <div className={style.designPhoto}>
                        <div className={style.cardPhoto}>
                            <img src={b1} alt='' className={style.cardImage}/>
                            <img src={b2} alt='' className={style.cardImage2}/>
                        </div>
                        <div className={style.cardPhoto}>
                            <img src={b3} alt='' className={style.cardImage}/>
                            <img src={b4} alt='' className={style.cardImage2}/>

                        </div>

                    </div>

                </div>
            </section>
            {/*end business card design*/}

            {/*start with lable design*/}
            <section className='mt-section_Space'>
                <div className={style.ParaDiv}>
                    <div className={style.designMainDiv}>
                        <h2 className={style.colourText}> COLOURFUL LABELS</h2>
                        <h1 className={style.businessText}>Label Designing</h1>
                        <p className={[style.businessPara,style.businessTitle,style.businessParaNew].join(' ')}>
                            Labels That Speak Volumes (Without
                            Saying a Word)
                            Your products deserve labels that stand out! loopy.lk designs eyecatching labels that not only communicate information clearly but
                            also tell your brand story. We'll help you create labels that grab
                            attention on shelves and leave a lasting impression on your
                            customers.
                        </p>
                    </div>

                    <div className={style.designPhoto}>
                        <div className={style.cardPhoto}>
                            <img src={label1} alt='' className={style.cardImage}/>
                            <img src={label2} alt='' className={style.cardImage2}/>
                        </div>
                        <div className={style.cardPhoto}>
                            <img src={label3} alt='' className={style.cardImage}/>
                            <img src={label4} alt='' className={style.cardImage2}/>

                        </div>

                    </div>

                </div>

            </section>
            {/*end with lable design*/}

            {/* start of requirement setting*/}
            <section className='mt-section_Space'>
                <div className={style.ParaDiv}>
                    <div className={style.designMainDiv}>
                        <h2 className={style.colourText}>COLOURFUL DESIGNS</h2>
                        <h1 className={style.businessText}>Other Required Designing</h1>
                        <p className={[style.businessPara,style.businessTitle,style.businessParaNew].join(' ')}>
                            Design that Keeps You Organized & Inspired
                            From staying on top of appointments to capturing life's moments,
                            loopy.lk designs calendars and diaries that are as functional as they
                            are visually inspiring. We don't just create planners and journals - we
                            craft tools that empower you to stay organized, express yourself, and
                            spark creativity.
                             We design for organization and inspiration, every page, every
                            day.

                        </p>
                    </div>

                    <div className='flex flex-wrap w-full justify-center items-center mt-[0rem] gap-4 xl:mt-[-3rem]'>

                        {/*Book*/}
                        <div className={style.itemDetails}>
                            <div className="lg:w-1/2 lg:justify-center lg:items-center">
                                <img src={asp1} alt='' className='w-[100%] xl:h-[35rem] xl:w-[38rem]'/>
                            </div>
                            <div className=' flex xl:w-1/2 xl:mr2'>
                                <div className={style.itemMainDiv}>
                                    <h1 className={[style.businessText, style.businessTextLg].join(' ')}>Book Cover
                                        Designings</h1>
                                    <p className={[style.businessPara, style.businessParaLg,style.leftGo].join(' ')}>
                                        Design Book Covers that Captivate
                                        Readers Before They Crack the Spine
                                        First impressions matter, especially in the world of books! loopy.lk
                                        crafts book cover designs that are visually stunning and perfectly
                                        capture the essence of your story. We don't just design covers, we
                                        design conversation starters that entice readers and get them hooked
                                        before they even turn the first page.
                                    </p>
                                    <div className={style.itemDetailsButton}>
                                        <button
                                            className={[style.viewButton, style.viewButtonLg].join(' ')}>
                                            View More
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/*Calender*/}
                        <div className={[style.itemDetails2, style.itemDetailsLg].join(' ')}>
                            <div className="xl:w-[50%] sm:w-[100%]">
                                <img src={asp3} alt='' className='w-[100%] xl:w-[687px]'/>
                            </div>
                            <div className=' flex xl:w-1/2'>
                                <div className={style.itemMainDiv}>
                                    <h1 className={[style.businessText, style.businessTextLg2].join(' ')}>Calender
                                        Designs</h1>
                                    <p className={[style.businessPara, style.businessParaLg].join(' ')}>
                                        Design Calendars That Captivate and
                                        Organize
                                        From planners to wall calendars, loopy.lk designs calendars that are as
                                        beautiful as they are functional. We'll help you create calendars that
                                        not only keep your customers organized but also showcase your brand
                                        personality throughout the year. Imagine eye-catching visuals and
                                        strategic design elements that boost brand awareness with every turn
                                        of the page
                                    </p>
                                    <button
                                        className={[style.viewButton, style.viewButtonLg2].join(' ')}>
                                        View More
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/*Diary*/}
                        <div className={style.itemDetails}>
                            <div className="lg:w-1/2 lg:justify-center lg:items-center">
                                <img src={asp2} alt='' className='w-[100%] lg:h-[35rem] lg:w-[38rem]'/>
                            </div>
                            <div className=' flex lg:w-1/2 lg:mr2'>
                                <div className={style.itemMainDiv}>
                                    <h1 className={[style.businessText, style.businessTextLg,style.diarySpecial].join(' ')}>Diary
                                        Designing</h1>
                                    <p className={[style.businessPara, style.businessParaLg].join(' ')}>
                                        Crafting Diaries to Spark Memories and Inspiration At loopy.lk, we design diaries that are more than just for jotting down schedules.
                                        Our diaries inspire creativity and capture life's special moments, allowing your customers to personalize and express themselves.
                                        Our passion lies in creating diaries that go beyond the ordinary. We understand that a diary is not
                                        just a tool for tracking appointments; it's a canvas for creativity and a repository of precious memories.
                                        With this in mind, we meticulously design diaries that serve as catalysts for inspiration and
                                        keepers of life's most treasured moments.

                                    </p>
                                    <div className={style.itemDetailsButton}>
                                        <button
                                            className={[style.viewButton, style.viewButtonLg].join(' ')}>
                                            View More
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* X banner */}
                        <div className={[style.itemDetails2, style.itemDetailsLg].join(' ')}>
                            <div>
                                <img src={asp4} alt='' className='w-[100%] xl:w-[502px]'/>
                            </div>
                            <div className=' flex xl:w-1/2'>
                                <div className={style.itemMainDiv}>
                                    <h1 className={[style.businessText, style.businessTextLg2].join(' ')}>X banner
                                        Designs</h1>
                                    <p className={[style.businessPara, style.businessParaLg].join(' ')}>
                                        Get your brand noticed with a custom X-banner designed by loopy.lk.
                                        We use clear visuals and compelling messaging to ensure your message gets seen and remembered.
                                        Elevate your brand visibility with a custom X-banner meticulously crafted by loopy.lk.
                                        We understand the importance of making a lasting impression,
                                        which is why we specialize in creating eye-catching designs that demand attention.
                                        Our team of experienced designers uses clear visuals and compelling messaging to make sure your
                                        message not only gets seen but also remembered long after the banner is taken down.
                                    </p>
                                    <button
                                        className={[style.viewButton, style.viewButtonLg2].join(' ')}>
                                        View More
                                    </button>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>
            </section>
            {/* end of requirement setting*/}

            {/*start of t-shirt*/}
            <section>

                <div className={style.TshirtDesign}>
                    <div className={style.tShirtMain}>
                        <h2 className={style.colourText}>COLOURFUL DESIGNING</h2>
                        <h1 className={style.businessText}>T shirt designing</h1>
                        <p className={[style.businessPara,style.businessTitle,style.businessParaNew].join(' ')}>
                            Design T-Shirts that Make a Statement (and Spark Sales)
                            Your brand deserves to be worn with pride! loopy.lk designs eye catching, conversation-starting t-shirts that showcase your brand
                            personality and resonate with your target audience. We don't just
                            create clothes, we create walking billboards that promote your brand
                            and leave a lasting impression.
                            We design t-shirts that people wear, not just own.

                        </p>
                    </div>
                    <TShirtSwipper/>

                </div>
            </section>
            {/*end of t-shirt*/}

            {/*Start of log design*/}
            <section className='mt-section_Space'>
                <div className='mr-[2rem] ml-[2rem]'>
                    <h1 className={style.businessText}>Logo
                        Designing</h1>

                    <div className='w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 mt-12 xl:h-[1200px] '>
                        <div className='bg-cover bg-center '>
                            <img src={taavetti} className={style.logoImage} alt='Gallery 2'/>
                        </div>
                        <div className='bg-cover bg-center'>
                            <img src={VOH} className={style.logoImage} alt='Gallery 4'/>
                        </div>
                        <div className='bg-cover bg-center'>
                            <img src={gamunu} className={style.logoImage} alt='Gallery 3'/>
                        </div>
                        <div className='bg-cover bg-center xl:mt-[-51px]'>
                            <img src={yasi} className={style.logoImage} alt='Gallery 5'/>
                        </div>
                        <div className='bg-cover bg-center xl:h-[486px] xl:mt-[-43px]'>
                            <img src={awalokana} className={style.logoImage} alt='Gallery 6'/>
                        </div>
                        <div className='bg-cover bg-center xl:mt-[-31px]'>
                            <img src={sandBeach} className={style.logoImage} alt='Gallery 7'/>
                        </div>
                        <div className='bg-cover bg-center xl:mt-[-45px]'>
                            <img src={tharushaHotel} className={style.logoImage} alt='Gallery 8'/>
                        </div>
                        <div className='bg-cover bg-center xl:mt-[25px] xl:h-[206px] xl:ml-[50px]'>
                            <img src={saffron} className={style.logoImage} alt='Gallery 9'/>
                        </div>
                        <div className='bg-cover bg-center xl:mt-[-45px]'>
                            <img src={tharushaTuvers} className={style.logoImage} alt='Gallery 10'/>
                        </div>
                    </div>

                </div>
            </section>
            {/*end of log design*/}

            {/*start of explore more*/}
            <section className='xl:mt-[60px]'>
                <div className={style.brocher}>
                    <div className='flex flex-col justify-center items-center '>
                        <h2 className={style.colourText}>EXPLORE MORE</h2>
                        {/*description*/}
                        <h1 className={style.businessText}>Brochure, Postcard, Flyer Designing</h1>
                        <p className={[style.businessPara,style.businessTitle,style.businessParaNew].join(' ')}>
                            Design that Pops! Brochures, Postcards & Flyers that
                            Connect.
                            Forget boring pamphlets and flimsy flyers. loopy.lk crafts show stopping brochures, captivating postcards, and impactful flyers that
                            grab attention and spark conversations. We don't just design print
                            materials, we create mini-brand experiences that leave a lasting
                            impression on your audience.
                            We design print that connects, not collects dust.

                        </p>
                    </div>

                    {/*  Descriptions one*/}
                    <div className={style.descriptionMainDesigns}>
                        <div className='flex flex-col  lg:w-1/2 {/*lg:m-[40px]*/}'>
                            <div className='flex text-left flex-col '>
                                <div className='flex flex-col xl:items-start xl:ml-[40px]'>
                                    <h2 className={style.colourText}>TREND</h2>
                                    <h1 className={[style.businessText, style.businessNewXl,style.brocherMainXL].join(' ')}>Brochure
                                        Designing</h1>
                                </div>

                                <p className={style.businessPara}>
                                    Brochures that Don't Get Tossed -
                                    Design that Makes a Lasting Impact
                                    In today's digital age, you might think brochures are a relic of the past.
                                    But think again! A well-designed brochure can still be a powerful
                                    marketing tool, especially when crafted by the loopy.lk team. We don't
                                    create flimsy flyers that get tossed aside; we design eye-catching,
                                    information-packed brochures that grab attention, spark interest, and
                                    leave a lasting impression on potential customers.
                                    Imagine handing out a brochure that's more like a mini-magazine.
                                    Stunning visuals showcase your products or services, clear and
                                    concise messaging highlights your unique selling points, and a
                                    captivating design reflects your brand personality. loopy.lk goes
                                    beyond just aesthetics; we strategically design brochures that are
                                    easy to navigate, informative, and most importantly, drive results.

                                </p>
                            </div>

                        </div>
                        {/************************************/}
                        <div className='flex gap-4 lg:w-1/2 xl:mt-[145px]'>
                            <div className={style.designItems}>
                                <img src={br1} alt='' className={style.itemPhotoRowOne}/>
                                <img src={br2} alt='' className={style.itemPhotoRowTwo}/>
                            </div>
                            <div className={style.designItems}>
                                <img src={br3} alt='' className={style.itemPhotoRowOne}/>
                                <img src={br4} alt='' className={style.itemPhotoRowTwo}/>

                            </div>
                        </div>
                    </div>

                    {/*Description two*/}
                    <div className={[style.descriptionMainDesigns,style.testLg].join(' ')}>
                        <div className={style.itemMainTwo}>
                            <div className={style.postCardDiv}>

                                <div className='flex flex-col xl:items-end xl:mr-[40px]'>
                                    <h2 className={style.colourText}>TREND</h2>
                                    <h1 className={style.businessText}>Flyer Designing</h1>
                                </div>


                                <p className={style.businessPara}>
                                    Tired of flyers that end up lining
                                    birdcages? loopy.lk designs eye-catching, attention-grabbing flyers
                                    that get noticed, get read, and spark action. We don't just create paper
                                    airplanes, we create mini-marketing machines that propel your brand
                                    forward.
                                    loopy.lk understands the power of a well-designed flyer. It can be a
                                    cost-effective way to promote upcoming events, announce new
                                    products, or drive traffic to your website. We'll ensure your flyer
                                    stands out from the crowd, generating interest and maximizing the
                                    return on your investment.
                                    Forget forgettable flyers, let loopy.lk design flyers that take flight (and
                                    drive results)!
                                </p>
                            </div>

                        </div>
                        <div className='flex gap-4 lg:m-[40px]'>
                            <div className={style.designItems}>
                                <img src={f1} alt='' className={style.itemPhotoRowOne}/>
                                <img src={f2} alt='' className={style.itemPhotoRowTwo}/>
                            </div>
                            <div className={style.designItems}>
                                <img src={f3} alt='' className={style.itemPhotoRowOne}/>
                                <img src={f4} alt=''  className={style.itemPhotoRowTwo}/>

                            </div>
                        </div>
                    </div>

                    {/* Description three*/}
                    {/*<div className={style.descriptionMainDesigns}>
                        <div className={style.itemMainTwo}>
                            <div className={style.flyerDesign}>
                                <h2 className={style.colourText}>TREND</h2>
                                <h1 className={style.businessText}>Flyer Designing</h1>
                                <p className={style.businessPara}>
                                    Et labore harum non nobis ipsum eum molestias mollitia et corporis praesentium a
                                    laudantium internos. Non quis eius quo eligendi corrupti et fu
                                    giat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                                    quibusdam!Et labore harum non nobis ipsum eum molestias mollitia
                                    et corporis praesentium a laudantium internos. Non quis eius quo eligendi corrupti
                                    et fugiat nulla qui soluta recusandae i
                                    giat nulla qui soluta recusandae in maxime quasi aut ducimus illum aut optio
                                    quibusdam!Et labore harum non nobis ipsum eum molestias mollitia
                                    et corporis praesentium a laudantium internos. Non quis eius quo eligendi corrupti
                                    et fugiat nulla qui soluta recusandae i
                                    n maxime quasi aut ducimus illum aut optio quibusdam!!
                                </p>
                            </div>

                        </div>
                        **********************************
                        <div className='flex gap-4 lg:m-[40px]'>
                            <div className={style.designItems}>
                                <img src={archi} alt='' className={style.itemPhotoRowOne}/>
                                <img src={archi2} alt='' className={style.itemPhotoRowTwo}/>
                            </div>
                            <div className={style.designItems}>
                                <img src={archi1} alt='' className={style.itemPhotoRowOne}/>
                                <img src={archi3} alt='' className={style.itemPhotoRowTwo}/>

                            </div>
                        </div>
                    </div>*/}
                    <div className='flex flex-col'>

                       {/* hoarding 1*/}
                        <div className={style.hoardingDesign}>
                            <div className='flex '>
                                {/*<div className={style.hordingImageDiv}>
                                    <img src={hoading1} alt='' className={[style.hoardingImage,style.hoardingLeftLg].join(' ')}/>
                                </div>*/}
                                <div className={[style.hordingImageDiv,style.hoardingDownMd].join(' ')}>
                                    <img src={hoading1} alt='' className={[style.hoardingImage,style.hoardingUpLg].join(' ')}/>
                                </div>


                            </div>
                            {/************************************/}
                            <div className={style.hoardingDesignMain}>
                                <div className={style.itemMainTwo}>

                                    <div className='flex flex-col xl:items-start xl:ml-[40px]'>
                                        <h2 className={style.colourText}>EXPLORE MORE</h2>
                                        <h1 className={[style.businessText, style.businessNewXl].join(' ')}>Hoarding
                                            Designing</h1>
                                    </div>


                                    <p className={style.businessPara}>
                                        Hoarding Design that Stops Traffic
                                        (and Hearts)
                                        Make a statement before they even reach your door! loopy.lk designs
                                        show-stopping hoarding campaigns that grab attention, ignite
                                        curiosity, and leave a lasting impression. We don't just create
                                        billboards, we craft visual experiences that capture the imagination
                                        and drive results.
                                        Whether you're announcing a grand opening, promoting a limited-time
                                        offer, or simply building brand awareness, loopy.lk will help you make
                                        the most of this high-impact advertising format. We'll work with you to
                                        develop a concept that's eye-catching, memorable, and drives
                                        engagement.
                                        Don't blend into the background, let loopy.lk design a hoarding that
                                        stops traffic (and hearts)!

                                    </p>
                                </div>

                            </div>

                        </div>

                        {/* hoarding 2*/}
                        <div className='flex w-full  '>
                            <div className={[style.hoardingDesign2Div, style.menuUpLg].join(' ')}>
                            <div className={style.menuDesignDiv}>
                                    <h2 className={style.colourText}>EXPLORE MORE</h2>
                                    <h1 className={style.businessText}>Menu designing</h1>
                                    <p className={[style.businessPara,style.businessTitle,style.businessParaNew].join(' ')}>
                                        Design Menus that Make Mouths Water (and Sales Sizzle)
                                        Your menu is more than just a list of dishes - it's a sales tool! loopy.lk
                                        crafts mouthwatering menu designs that showcase your culinary
                                        creations and entice customers to order. We don't just design menus,
                                        we design dining experiences that tantalize taste buds and boost your
                                        bottom line.
                                        We design menus that tempt, not just inform.
                                    </p>
                                </div>

                            </div>
                            <div className={style.hoardingDesign2}>
                                <div className='w-full flex-col h-[25rem] md:w-[610px]'>
                                    <img src={hoading2} alt='' className='w-[70px] h-full md:w-[719px] md:ml-[0px]'/>
                                </div>

                            </div>

                        </div>

                        {/*menu card Swipper*/}
                        <MenuCardSwipper/>
                    </div>


                </div>
            </section>
            {/*end of explore more*/}

           {/* <section className='mt-section_Space'>
                <div className='flex flex-col ml-12 mr-12'>
                    <div className={style.invitationDiv}>
                        <h2 className={style.colourText}>EXPLORE MORE</h2>
                        description
                        <h1 className={style.businessText}>Invitation Designing</h1>
                        <p className={[style.businessPara,style.businessTitle,style.businessParaNew].join(' ')}>
                            Design Invitations that Spark Excitement (and RSVPs!)
                            Make a lasting first impression before the event even starts! loopy.lk
                            crafts eye-catching, attention-grabbing invitation designs that set the
                            tone for your event and get people excited to RSVP. We don't just
                            create paper notifications, we design memorable experiences that
                            build anticipation from the get-go.
                            We design invitations that get people saying "Yes!"
                        </p>
                    </div>
                    <InvitatipnSwipper/>

                </div>
            </section>*/}

        </section>
    );
}
