import {Swiper, SwiperSlide} from 'swiper/react';
import '../../Home/Swipper/swippercss.css'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/free-mode'/*
import {FreeMode, Pagination} from 'swiper/modules';
import {Autoplay, Navigation} from "swiper/types/modules"*/

import 'swiper/css/navigation';

import {Autoplay, Navigation} from 'swiper/modules';
import {FreeMode, Pagination} from 'swiper/modules';


export function SocialMediaSwipper() {
    const brand = [
        {
            id: "01",
            image: "T shirt new 1.png",

        },
        {
            id: "02",
            image: "T shirt new 2.png",

        },
        {
            id: "03",
            image: "T shirt new 4.png",

        }, {
            id: "03",
            image: "T shirt new 1.png",

        }, {
            id: "03",
            image: "T shirt new 2.png",

        }
    ]
    return (
        <div className='flex mt-10  '>
            <Swiper
                modules={[Autoplay, Navigation, Pagination, FreeMode]} // Add modules as options

                autoplay={{delay: 3000}} // Autoplay with a 3-second delay


                breakpoints={{
                    340: {slidesPerView: 2, spaceBetween: 15},
                    700: {slidesPerView: 3, spaceBetween: 18},
                }}
                freeMode={true}
                pagination={{
                    clickable: true,

                }}

                className='max-w-[100%] /*lg:max-w-[100%]*/ '
            >
                {brand.map((brand, index) => (
                    <SwiperSlide className='' key={index}>
                        <div
                            className='flex rounded justify-center items-center flex-col  h-[90%] {/*lg:h-[88%]*/} w-[98%]  {/*lg:mt-6*/} mb-12 {/*lg:mb-20*/}   '
                        >
                            <div className='w-[90%] {/*lg:w-[54%]*/}'>
                                <img src={require(`../../../../image/Loopy web/${brand.image}`)}
                                     alt=''/>
                            </div>

                        </div>
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    );
}
