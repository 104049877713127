import {Footer} from "../Footer/Footer";
import React from "react";
import MobileMenu from "../NavBar/MobileMenu";
import {MainContext} from "../MainContext/MainContext";

export function DefaultLayOut() {
    return (
        <>

            <MobileMenu/>
           <MainContext/>
            <Footer/>
        </>

    );
}
