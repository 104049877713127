import Design from "../../../image/Loopy web/p6.jpg";
import birth6 from '../../../image/Loopy web/BirCake2.jpg'
import birth1 from '../../../image/Loopy web/BirMom.jpg'
import birth2 from '../../../image/Loopy web/BirCake1.jpg'
import birth3 from '../../../image/Loopy web/bir1.png'
import birth5 from '../../../image/Loopy web/BirGirl.jpg'
import birth7 from '../../../image/Loopy web/BirthdayFirst.jpg'
import {SwiperPhoto} from "./SwiperPhoto/SwiperPhoto";

import item1 from '../../../image/Loopy web/Jamanaran.jpg'
import item2 from '../../../image/Loopy web/pro1.jpg'
import item3 from '../../../image/Loopy web/pro2.jpg'

import archi from '../../../image/Loopy web/ar1.jpg'
import archi1 from '../../../image/Loopy web/ar2.jpg'
import archi2 from '../../../image/Loopy web/ar3.jpg'
import archi3 from '../../../image/Loopy web/ar4.jpg'
import {Gallery} from "./Gallery/Gallery";
import styles from '../../page/Photograpy/Photography.module.css'

export function Photography() {
    const src = "https://www.youtube.com/embed/V_cioLTaDvQ?si=11rvRDAdXh3eFrWK";

    return (

        <section className='font-poppins'>
            {/* start with About section*/}
            <section className='font-poppins'>

                <div className='flex flex-col gap-4'>
                   {/* image*/}
                    <div className={styles.bg}
                         style={{backgroundImage: `url(${Design})`,}}>
                        <div className='  left-0 right-0 transform -translate-y-1/2 '>

                            <h1 className={styles.bgTitle}>
                                Photography
                            </h1>
                        </div>

                    </div>
                   {/* Photo*/}
                    <div className={styles.photoFirstDiv}>
                        {/*paragraph*/}
                        <div className={styles.paragraphFirstDiv}>
                            <div className={styles.paragraphSecondDiv}>
                                <div className={styles.paragraphTheirdDiv}>
                                    <div className={styles.paragraphFourthDiv}>
                                        {/* title*/}
                                        <h2 className={styles.paragraphH1}>COLOURFULL PHOTOGRAPHY</h2>
                                        {/* sub title*/}
                                        <h1 className={styles.paragraphSubH1}>Capture More Than Images  </h1>
                                        <h1 className={styles.paragraphSubH1Two}>Craft Visual Stories</h1>
                                    </div>
                                    <div className={styles.paraMainDiv}>
                                        <p className={[styles.praPara, styles.paraParaExperience, styles.mainPara].join(' ')}>
                                            We believe photos are more than just pixels. loopy.lk designs
                                            photography with purpose, crafting visuals that tell your brand story
                                            and connect with your audience on an emotional level.
                                        </p>


                                    </div>
                                    <button
                                        className={styles.button}>
                                        Book Now
                                    </button>

                                </div>

                                <div className='flex mr-[10px] '>
                                    <img src={birth7} alt='' className='rounded xl:h-[414px] xl:w-[700px] xl:mt-[10px]'/>

                                </div>


                            </div>

                        </div>
                        {/*image*/}
                        {/*<div className={styles.paragraphImage}>
                            <div className=' rounded-md '>
                                <img src={ph1} alt=""/>

                            </div>
                            <img src={loopy1} alt=''
                                 className=' '/>


                        </div>*/}
                    </div>
                </div>

            </section>
            {/*end of About section*/}
            {/*video*/}
          {/*  <section>
                <div className={styles.videoFirstDiv} style={{backgroundImage: `url(${education})*!/ `}}>
                    <div className={styles.videoSecondDiv}>
                        <iframe
                            className='w-full lg:h-[500px] lg:w-[700px]'
                            width="700"
                            height="200"
                            src={src}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className={styles.videoLastDiv}>
                        <h1 className={styles.videoLastDivH1}>Happy Wedding</h1>

                    </div>
                </div>

            </section>*/}
            {/*end of video*/}

            {/*trend*/}
              <section className={styles.trendFirstDiv}>
                <div className={styles.trendSecondDiv} >
                    {/*content
                    video*/}
                    {/*<div
                        className={styles.trendImageDiv}>
                        <img src={engage} className={'hidden sm:block'} style={{transform: 'rotate(323deg) '}} alt=''/>
                    </div>*/}
                    {/*<div className='flex  md:w-[97%]  md:flex-row  md:ml-1  h-auto mr-4  '>
                        <div className=' flex gap-5 flex-col mr-[16px]'>
                            <div className='flex   md:ml-24 lg:ml-[3rem]  md:mt-2 flex-col'>
                                <div className='flex flex-col  gap-2 mt-[-50px]'>
                                    <h2 className={styles.paragraphH1}>TREND</h2>
                                    <div className='flex flex-col lg:gap-2 md:gap-3 gap-3'>
                                        <h1 className='text-subTitle text-4xl font-extrabold text-center'>Wedding Photography</h1>
                                        <p className={styles.praPara}>
                                            Et labore harum non nobis ipsum eum molestias mollitia et corporis
                                            praesentium a
                                            laudantium internos. Non quis eius quo eligendi corrupti et fugiat nulla qui
                                            soluta
                                            recusandae in maxime quasi aut ducimus illum aut optio quibusdam!
                                        </p>
                                    </div>
                                </div>
                                <img src={ring} alt='' className=' top-[-54px] right-[8rem] w-[380px] ml[-144px] '/>


                            </div>
                            <div className='flex  flex-row gap-7 md:flex-col ml-4  w-[500px] h-[167px]'>
                                <div className='flex  '>
                                    <img src={photo1} alt='' className='rounded'/>
                                </div>
                                <div className='flex  '>
                                    <img src={photo2} alt='' className='rounded'/>

                                </div>
                                <div className='flex  '>
                                    <img src={photo2} alt='' className='rounded'/>

                                </div>


                            </div>

                        </div>

                    </div>*/}

                </div>
            </section>
            {/*end of trend*/}

            {/* start of Explore more*/}
            <section className=' mt-[-54px]'>
                <div className='flex flex-col xl:mt-[50px]'>
                    {/*content*/}
                    <div className='flex justify-center items-center flex-col gap-3 xl:mt-[20px]'>
                        <h2 className={[styles.paragraphH1,styles.exploreMoreLg].join(' ')}>EXPLORE MORE</h2>
                        <h1 className={styles.paragraphSubH1New}>Our Some  photos before shoot</h1>
                    </div>
                   {/* gallery*/}
                    <div className='flex'>
                        <Gallery/>
                    </div>
                </div>
            </section>
            {/* end of Explore more*/}

            {/*start of birthdy shoot*/}
            <section className='mt-section_Space'>
                <div className='flex md:flex-col flex-col'>
                    {/*content
                    video*/}
                    <div className='flex md:w-[97%] md:flex-row md:ml-1 h-auto ml-[11px]'>
                        <div className=' flex gap-5 flex-col'>
                            <div className='flex  lg:ml-[3rem] md:mt-2 flex-col md:ml-[1rem]'>
                                <div className='flex flex-col gap-2'>
                                    <h2 className='text-title font-bold text-center mt-[-47px]'>THREND</h2>
                                    {/*description*/}
                                    <div className='flex flex-col lg:gap-2 md:gap-3 gap-3'>
                                        <h1 className='text-subTitle text-4xl font-extrabold text-center'>Birth Day Photography</h1>
                                        <p className={[styles.praPara,styles.paraParaExperience].join(' ')}>
                                            Capture the Magic - Birthday Photography that Shines
                                            Birthdays are a celebration of life, laughter, and love! loopy.lk
                                            captures all those special moments with stunning birthday
                                            photography that you'll cherish for years to come. We don't just take
                                            pictures, we document the joy, the excitement, and the unique
                                            memories that make each birthday party unforgettable.
                                            loopy.lk: We go beyond snapshots, we capture birthday stories.
                                        </p>

                                    </div>
                                </div>
                               {/* cacke*/}
                               {/* <div className='flex '>
                                    <img src={birthday}  alt='' style={{transform: 'rotate(8deg)'}} className='hidden sm:block md:block'/>

                                </div>*/}

                            </div>
                           {/* flex photo*/}
                            <div className='flex gap-4 md:flex-row flex-col w-fit mr-[10px] md:ml-[26px]'>
                                <div className='flex '>
                                    <img src={birth1} alt='' className='rounded w-[2500px] h-[400px] md:h-[206px] xl:h-[400px]'/>
                                </div>
                                <div className='flex  '>
                                    <img src={birth2} alt='' className='rounded w-[2500px] h-[400px] md:h-[206px] xl:h-[400px]'/>

                                </div>
                                <div className='flex  '>
                                    <img src={birth3} alt='' className='rounded w-[2500px] h-[400px] md:h-[206px] xl:h-[400px]'/>

                                </div>
                                <div className='flex  '>
                                    <img src={birth5} alt='' className='rounded w-[2500px] h-[400px] md:h-[206px] xl:h-[400px]'/>

                                </div>

                                <div className='flex  '>
                                    <img src={birth6} alt='' className='rounded w-[2500px] h-[400px] md:h-[206px] xl:h-[400px]'/>

                                </div>


                            </div>

                        </div>

                    </div>
                  {/*  <div
                        className='flex justify-end md:justify-center md:flex-row md:mt-[10px]  flex-col items-center w-4/12 h-[30rem] md:w-[98.3%] mr-12 hidden sm:block md:block'>
                        <img src={birth3} alt='' className='w-[90%] h-[90%]  '/>
                    </div>*/}

                </div>
            </section>
            {/*End of birthdy shoot*/}

            {/*start of Event photography*/}
            <section className=' mt-[3rem]'>
                <div className=' text-right'>
                    <h2 className={[styles.paragraphH1,styles.smallTitlelLg].join(' ')}>THREND</h2>
                    {/*description*/}
                    <h1 className={[styles.paragraphSubH1,styles.mainTitleLgEvent].join(' ')}>Event Photography</h1>
                    <p className={[styles.praPara, styles.paraParaExperience,styles.smallParaLg].join(' ')}>
                        Don't just let your event fade into
                        memory. loopy.lk captures the exhilaration, laughter, and heartfelt
                        moments that make your special occasion truly unforgettable.


                    </p>
                </div>
                <div>
                    <SwiperPhoto/>

                </div>

            </section>
            {/*End of Event photography*/}

            {/*start of product photography*/}
            <section className='mt-[45px]'>
                <div className='flex  ml-[1rem] mr-[1rem] flex-col-reverse'>
                    <div className='flex-col  gap-4'>
                        <div className='flex'>
                            <img src={item2} alt='' className='w-[100%] h-[296px] mt-[45px]  lg:w-[100%] xl:h-[950px]'/>
                        </div>
                        <div className='flex mt-10 '>
                            <img src={item1} alt='' className='w-[100%] h-[296px]  lg:w-[100%] xl:h-[1302px] '/>
                        </div>

                    </div>
                    <div className='flex-col '>
                        <div className=' text-right'>
                            <h2 className={[styles.paragraphH1,styles.smallTitlelLg].join(' ')}>THREND</h2>
                            {/*description*/}
                            <h1 className={[styles.paragraphSubH1,styles.mainTitleLgEvent].join(' ')}>Product Photography</h1>
                            <p className={[styles.praPara,styles.paraParaExperience,styles.smallParaLg].join(' ')}>
                                Showcase Your Products in the Best Light - Product
                                Photography that Sells
                                Make your products the stars of the show! loopy.lk creates stunning
                                product photography that captures attention, highlights features, and
                                drives sales. We don't just take pictures, we craft compelling visuals
                                that showcase your products in their best light and entice customers
                                to click "add to cart."
                                loopy.lk: We don't just photograph products, we make them
                                irresistible.

                            </p>
                        </div>
                        <div className=' w-full'>
                            <img src={item3} alt='' className='w-[100%] h-full mt-[25px]'/>
                        </div>


                    </div>
                </div>
            </section>
            {/*End of product photography*/}
            <section className='mt-[3rem]'>
                <div className='flex flex-col justify-center items-center w-full gap-8'>
                    <div className=' flex justify-center items-center flex-col'>
                        <h2 className='text-title font-bold'>THREND</h2>
                        {/*description*/}
                        <h1 className='text-subTitle font-extrabold text-4xl text-center md:text-justify '>Architecture Photography</h1>
                        <p className={[styles.praPara,styles.paraParaExperience,styles.smallParaLg].join(' ')}>
                            Architectural Photography that Tells
                            a Story
                            Architecture isn't just about design, it's about emotion. loopy.lk crafts
                            breathtaking architectural photography that showcases the beauty,
                            scale, and artistry of your space. We don't just capture buildings, we
                            capture the stories they tell.
                            loopy.lk: We go beyond brick and mortar, we capture the soul of
                            architecture.

                        </p>

                    </div>
                    <div className='flex  mr-[1rem] ml-[1rem] gap-6 flex-col md:flex-row'>
                        <div className=' flex-col  md:h-[40rem] xl:h-[1507px] h-[30rem]'>
                            <img src={archi} alt='' className='w-[100%] h-1/2'/>
                            <img src={archi2} alt='' className='w-[100%] h-1/2 mt-6'/>
                        </div>
                        <div className=' flex-col  mt-6 md:mt-[0px] md:h-[40rem] xl:h-[1507px] h-[30rem]'>
                            <img src={archi1} alt='' className='w-[100%] h-1/2'/>
                            <img src={archi3} alt='' className='w-[100%] h-1/2 mt-6'/>

                        </div>

                    </div>

                </div>

            </section>
        </section>
    );
}
