import '../../About Us/Quection/queCss.css'
import {useState} from "react";

export function Quection() {
    const set = [
        {
        id: "01",
            que:"What kind of marketing solutions do you offer?",
        answer: "loopy.lk offers a comprehensive suite of marketing services, from\n" +
            "crafting a powerful brand identity and designing stunning websites to\n" +
            "developing targeted marketing campaigns and creating captivating\n" +
            "multimedia content."
    },
        {
            id: "02",
            que:"How can loopy.lk help my business grow?",

            answer: "By implementing effective marketing strategies, loopy.lk can help you\n" +
                "reach new audiences, increase brand awareness, generate leads, and\n" +
                "ultimately drive sales. "
        },
        {
            id: "03",
            que:"What can I expect when I partner with loopy.lk?",

            answer: "At loopy.lk, we believe in fostering strong partnerships with our\n" +
                "clients. You can expect a dedicated team of experienced marketing\n" +
                "professionals who are passionate about your success. "
        },
    ]
    const [selected,setSelected]=useState(null)
    const toggle = ({index}: { index: any })=>{
        if (selected === index){
            return setSelected(null)
        }
        setSelected(index)
    }
    return (

        <div className="flex flex-col">
            {set.map((item, index) => (
                <div key={index} className='item flex bg-[#f0ebe1] flex-col mt-4 '>
                    <div className='title flex text-[11px] xl:text-[15px] font-semibold justify-between  items-center cursor-pointer   h-10 ml-2 mr-2 ' onClick={()=>toggle({index: index})}>
                        <h2>{item.que}</h2>
                        <span>{selected === index ? '-' : '+'}</span>
                    </div>
                    <div className={selected === index ? "content show":"content " } style={{transition:'all 0.5s'}}>{item.answer}</div>
                </div>
                ))}
        </div>
    );
}
