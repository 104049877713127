import gallery1 from "../../../../image/Loopy web/p1.jpg"
import gallery2 from "../../../../image/Loopy web/p2.jpg"
import gallery3 from "../../../../image/Loopy web/p3.jpg"
import gallery4 from "../../../../image/Loopy web/p4.jpg"
import gallery5 from "../../../../image/Loopy web/p5.jpg"
import gallery6 from "../../../../image/Loopy web/p6.jpg"
import gallery7 from "../../../../image/Loopy web/fields-bali.jpg"
import gallery8 from "../../../../image/Loopy web/Place (1).jpg"
import gallery9 from "../../../../image/Loopy web/Jungle-Beach.jpg"
import gallery10 from "../../../../image/Loopy web/droneShot.jpg"
import gallery11 from "../../../../image/Loopy web/RJP_7790.jpg"
import gallery12 from "../../../../image/Loopy web/GIH01586.png"
/*make*/


export function Gallery() {
    return (
        <div
            className=' grid grid-cols-1 md:grid-cols-2 grid-rows-3 mr-[12px] ml-[12px] mt-12 gap-4 xl:grid-cols-3 w-fit'>

            <div className='bg-cover bg-center  col-span-1 h-[400px]'>
                <img src={gallery1} className='h-full    transition duration-3 hover:scale-gallery'/>

            </div>

            <div className='bg-cover bg-center col-span-1 h-[400px]'>
                <img src={gallery2} className='h-full   transition duration-3 hover:scale-gallery'/>
            </div>

            {/* <div className='bg-cover bg-center   row-span-2 '>
                <img src={travel} className='h-full transition duration-3 hover:scale-gallery'/>

            </div>*/}

            <div className='bg-cover bg-center col-span-1 '>
                <img src={gallery4} className='h-full   transition duration-3 hover:scale-gallery'/>
            </div>

            <div className='bg-cover bg-center col-span-1 h-[400px]'>
                <img src={gallery5} className='h-full   transition duration-3 hover:scale-gallery'/>
            </div>
            <div className='bg-cover bg-center col-span-1 h-[400px]'>
                <img src={gallery6} className='h-full   transition duration-3 hover:scale-gallery'/>
            </div>
            <div className='bg-cover bg-center col-span-1 '>
                <img src={gallery7} className='h-full   transition duration-3 hover:scale-gallery'/>
            </div>
            <div className='bg-cover bg-center col-span-1 '>
                <img src={gallery9} className='h-full   transition duration-3 hover:scale-gallery'/>
            </div>

            <div className='bg-cover bg-center col-span-1 '>
                <img src={gallery3} className='h-full   transition duration-3 hover:scale-gallery'/>
            </div>
            <div className='bg-cover bg-center col-span-1 h-[400px]'>
                <img src={gallery11} className='h-full   transition duration-3 hover:scale-gallery'/>
            </div>
            <div className='bg-cover bg-center col-span-1 h-[400px]'>
                <img src={gallery8} className='h-full  transition duration-3 hover:scale-gallery'/>
            </div>
            <div className='bg-cover bg-center col-span-1 h-[400px]'>
                <img src={gallery10} className='h-full   transition duration-3 hover:scale-gallery'/>
            </div>
            <div className='bg-cover bg-center col-span-1 h-[400px]'>
                <img src={gallery12} className='h-full   transition duration-3 hover:scale-gallery'/>
            </div>


        </div>
    );
}
