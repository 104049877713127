import { RiFacebookCircleLine,RiInstagramLine,RiYoutubeLine, RiWhatsappLine ,RiMapPinLine,RiPhoneLine, RiMailLine } from "react-icons/ri";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import footerImg from '../../../image/Loopy web/img_2.png'
import style from '../../common/Footer/NavBar.module.css';
/*import AOS from "aos";*/


export function Footer() {
    /*AOS*/
   /* useEffect(() => {
        AOS.init({
                offset: 100, // offset (in px) from the original trigger point
                delay: 100, // values from 0 to 3000, with step 50ms
                duration: 1000, // values from 0 to 3000, with step 50ms
                easing: 'ease-in-out', // default easing for AOS animations
                once: false, // whether animation should happen only once - while scrolling down
                mirror: true, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom',
            }

        );
        AOS.refresh()

    }, []);*/

    /*AOS End*/

    return (
        <div className={style.NavMainRow}>
            <div className='bg-cover flex-wrap xl:w-[1600px]' style={{backgroundImage: `url(${footerImg})`}}>
                <div className={style.navBarSecond}>
                    <div className='flex flex-col items-center lg:ml-24 mt-10 w-full lg:w-[18rem] xl:w-[23rem]'>
                        <h1 className='text-subTitle font-semibold text-2xl ml-4 lg:ml-0 text-center'
                            >Loopy Holding</h1>
                        <p className='mt-3 text-center w-[294px]'  >
                            To move on in the process as one family keeping in touch with the latest technologies
                        </p>
                        <div className='flex gap-4 mt-3 text-center'>
                            <RiFacebookCircleLine className='text-title'/>
                            <RiInstagramLine className='text-title'/>
                            <RiYoutubeLine className='text-title'/>
                            <RiWhatsappLine className='text-title'/>
                        </div>
                    </div>


                    <div
                        className='flex flex-col gap-20 mt-10 items-center lg:flex-row lg:flex md:flex-row md:mt-[-23px]'>
                        <div className='flex-col   md:ml-[32px] sm:ml-[-75px] mr-[40px]'>
                            <h5 className='font-semibold md:mt-[-37px] lg:mt-[9px] xl:mt-[70px]'>Company</h5>
                            <ul className="text-[14px]  sm:ml-[83px] xl:ml-[62px]">
                                <li className="mt-5 lg:mt-3 sm:ml-[-39px] xl:ml-[-61px] md:ml-[-80px]">
                                    <Link to="/">About Us</Link>
                                </li>
                                <li className=" mt-5 lg:mt-3 xl:ml-[-61px] md:ml-[-80px]">
                                    <Link to="/about">Pages</Link>

                                </li>
                                <li className=" mt-5 lg:mt-3 xl:ml-[-61px] md:ml-[-80px]">
                                    <Link to="/tour">Services</Link>

                                </li>
                                <li className=" mt-5 lg:mt-3 xl:ml-[-61px] md:ml-[-80px]">
                                    <Link to="/tour">Portfolio</Link>

                                </li>
                            </ul>
                        </div>
                        <div className='flex-col mt-10  '>
                            <h5 className='font-semibold mt-[-63px] lg:mt-[23px]  md:mt-[-36px] '>Destinations</h5>
                            <ul className="text-[14px] sm:ml-[31px] xl:ml-[0px] md:ml-[0px]" >
                                <li className=" mt-5 lg:mt-3  ">
                                    <Link to="/designing">Creative Designing</Link>

                                </li>
                                <li className=" mt-5 lg:mt-3">
                                    <Link to="/photography">Multimedia</Link>

                                </li>
                                <li className=" mt-5 lg:mt-3 ">
                                    <Link to="/website">Web Designing</Link>

                                </li>
                                <li className=" mt-5 lg:mt-3 ">
                                    <Link to="/social">Social Media</Link>

                                </li>
                            </ul>
                        </div>
                        <div className='flex-col mt-10 lg:mr-3 '>
                            <h5 className='font-semibold mt-[-63px] ml-[96px]  lg:mt-[45px] lg:ml-[46px] xl:ml-[160px] md:ml-[119px]'
                                >Join Our Newsletter</h5>
                            <div className='flex gap-2 mt-5 justify-center flex-col items-center' >
                                <input
                                    className='outline-none bg-[#EEEEFF] flex text-center w-4/5  h-[55px] lg:[53px] rounded xl:ml-[38px] sm:ml-[0px]'
                                    placeholder='your email Address'/>
                                <button
                                    className='flex lg:w-[27%] bg-title mt-1 h-10 text-center justify-center items-center text-white rounded w-[100px]'>
                                    Subscribe
                                </button>
                            </div>
                            <div className='flex gap-2 mt-5 text-center ml-[62px]'>
                                <p className='text-gray-500 w-4/5 '>
                                    * Will send you weekly updates for your better
                                    our packages.
                                </p>

                            </div>

                        </div>
                    </div>


                </div>
                <p className='mt-2 lg:mt-4 justify-center text-center text-[14px] flex'>Copyright @ Loopy Holding 2024. All Rights Reserved.</p>

            </div>
        </div>);
}
