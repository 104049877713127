import React, {useEffect, useState} from 'react';
import { IoCloseOutline, IoMenuOutline } from 'react-icons/io5';
import { MdArrowDropDown } from "react-icons/md";
import {Link} from "react-router-dom";

const Nav = () => {
    const links = [
        { name: "Home", link: "/" },
        { name: "About Us", link: "/aboutUs" },
        { name: "Services", link: "/", dropdown: true },
        { name: "Portfolio", link: "https://drive.google.com/drive/folders/1JZetdqECckAXGVepsHX3BzOQdsy0m8ec?usp=sharing" },
        { name: "Contact", link: "/contact" },

    ];

    const [open, setOpen] = useState(false);
    const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);
    const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
    const [color, setColor] = useState(false);

    const changeColor = () => {
        // Check if the window width is in the range for md and lg screens
        const isMdLgScreen = window.innerWidth >= 768; // Adjust the value as needed

        // Update color state only for md and lg screens
        if (isMdLgScreen) {
            setColor(window.scrollY >= 90);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', changeColor);
        return () => {
            window.removeEventListener('scroll', changeColor);
        };
    }, []);


    /*Drop down hide when click*/
    useEffect(() => {
        const handleClickOutside = (event:any) => {
            const aboutDropdown = document.getElementById('aboutDropdown');
            const servicesDropdown = document.getElementById('servicesDropdown');

            if (
                aboutDropdown && !aboutDropdown.contains(event.target) &&
                servicesDropdown && !servicesDropdown.contains(event.target)
            ) {
                setAboutDropdownOpen(false);
                setServicesDropdownOpen(false);
            }
        };

        document.body.addEventListener('click', handleClickOutside);

        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };

    }, [setAboutDropdownOpen, setServicesDropdownOpen]);

    return (
        <div className={`w-full fixed top-0 left-0 z-10 font-poppins ${color ? 'md:bg-black ' : ''} `} >
            <div className="md:flex items-center justify-between  py-4 md:px-10 px-7 lg:px2">
                <div className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-gray-800">
                    <span className="text-3xl text-indigo-600 mr-1 pt-2"></span>
                    <h2 className='hidden'>Designer</h2>
                </div>

                <div onClick={() => setOpen(!open)} className="text-3xl absolute right-8 top-2 cursor-pointer md:hidden">
                    {open ?
                        <div className="w-[48px] h-[35px] flex justify-around items-center   border-orange border-2 rounded bg-black">
                        <IoCloseOutline className='text-white' />
                        </div>
                            :
                        <div className="w-[48px] h-[35px] flex justify-around items-center  border-orange border-2 rounded bg-black">

                        <IoMenuOutline className='text-white' />
                        </div>
                    }
                </div>

                <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static text-white 
                md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all bg-black md:bg-transparent  duration-500 ease-in ${open ? 'top-0' : 'top-[-490px]'}`}>
                    {links.map((link) => (
                        <li key={link.name} className="md:ml-8 text-xl md:my-0 my-7 text-white">
                            {link.dropdown ? (
                                <div>
                <span
                    onClick={() => {
                        if (link.name === "Pages") {
                            setAboutDropdownOpen(!aboutDropdownOpen);
                            setServicesDropdownOpen(false); // Close other dropdown if open
                        }
                        if (link.name === "Services") {
                            setServicesDropdownOpen(!servicesDropdownOpen);
                            setAboutDropdownOpen(false); // Close other dropdown if open
                        }
                    }}
                    className="cursor-pointer duration-500 flex items-center"
                >
                    {link.name}
                    <MdArrowDropDown />
                </span>
                                   {/* {link.name === "Pages" && aboutDropdownOpen && (
                                        <ul
                                            id="aboutDropdown"
                                            className="ml-4 mt-2 xl:absolute xl:ml-[54rem] xl:top-[50px] xl:mt-2 xl:bg-white xl:text-black xl:rounded-md xl:shadow-md xl:opacity-100 xl:scale-100 xl:transform xl:transition-transform xl:duration-300 xl:origin-top xl:z-10
                                    lg:absolute lg:left-40 lg:top-full lg:bg-white lg:text-black lg:rounded-md lg:shadow-md lg:opacity-100 lg:scale-100 lg:transform lg:transition-transform lg:duration-300 lg:origin-top lg:z-10
                                    md:absolute md:left-0 md:top-full  md:bg-white md:text-black md:rounded-md md:shadow-md md:opacity-100 md:scale-100 md:transform md:transition-transform md:duration-300 md:origin-top md:z-10 md:mt-[-19px] md:ml-[18rem] lg:mt-[-6px] lg:ml-[33rem]">
                                            <li><a href="/aboutUs" onClick={() => setAboutDropdownOpen(false)}>About Us</a></li>
                                            <li><a href="/about" onClick={() => setAboutDropdownOpen(false)}>Contact Us</a></li>
                                             Add more About links here
                                        </ul>
                                    )}*/}
                                    {link.name === "Services" && servicesDropdownOpen && (
                                        <ul
                                            id="servicesDropdown"
                                            className="absolute mt-2 bg-white text-black rounded-md shadow-md opacity-100 scale-100 transform transition-transform duration-300 origin-top z-10
               xl:absolute md:ml-[288px] xl:top-[50px] xl:mt-2 xl:bg-white xl:text-black xl:rounded-md xl:shadow-md xl:opacity-100 xl:scale-100 xl:transform xl:transition-transform xl:duration-300 xl:origin-top xl:z-10
               lg:absolute lg:left-40 lg:top-full lg:bg-white lg:text-black lg:rounded-md lg:shadow-md lg:opacity-100 lg:scale-100 lg:transform lg:transition-transform lg:duration-300 lg:origin-top lg:z-10
               md:absolute md:left-0 md:top-full md:bg-white md:text-black md:rounded-md md:shadow-md md:opacity-100 md:scale-100 md:transform md:transition-transform md:duration-300 md:origin-top md:z-10 md:mt-[-18px] lg:ml-[508px]  lg:mt-[-7px] xl:ml-[890px]"
                                        >
                                            <li className="py-2 px-4 hover:bg-gray-100 transition-all duration-300 ease-in-out">
                                                <a href="/designing" onClick={() => setServicesDropdownOpen(false)}>Creative
                                                    Designing</a>
                                            </li>
                                            <li className="py-2 px-4 hover:bg-gray-100 transition-all duration-300 ease-in-out">
                                                <a href="/photography"
                                                   onClick={() => setServicesDropdownOpen(false)}>Multimedia</a>
                                            </li>
                                            <li className="py-2 px-4 hover:bg-gray-100 transition-all duration-300 ease-in-out">
                                                <a href="/website" onClick={() => setServicesDropdownOpen(false)}>Web
                                                    Designing</a>
                                            </li>
                                            <li className="py-2 px-4 hover:bg-gray-100 transition-all duration-300 ease-in-out">
                                                <a href="/social" onClick={() => setServicesDropdownOpen(false)}>Social Media</a>
                                            </li>
                                            {/* Add more Service links here */}
                                        </ul>
                                    )}


                                </div>
                            ) : (
                                <a href={link.link} className="duration-500">{link.name}</a>
                            )}
                        </li>
                    ))}
                    <button className='bg-orange text-white font-[Poppins] py-2 px-6 rounded md:ml-8 hover:bg-indigo duration-500' >
                         <Link to="/touch">Get Start </Link>
                    </button>
                </ul>
            </div>
        </div>
    );
};

export default Nav;
