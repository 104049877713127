import Design from "../../../image/Loopy web/aboutUsBack.jpg";
import {FaArrowRight} from "react-icons/fa6";
import loopy1 from '../../../image/Loopy web/LoopyOffice3.jpg';
import loopy2 from '../../../image/Loopy web/officeNew1.jpg'
import feature from '../../../image/Loopy web/goooo.png'
import asp1 from '../../../image/Loopy web/mar.png'
import asp2 from '../../../image/Loopy web/Web Design.png'
/*make*/
import asp3 from '../../../image/Loopy web/multimeeee-removebg-preview (1).png'
import asp4 from '../../../image/Loopy web/webbb.png'
import ask1 from '../../../image/Loopy web/asked quections.png'
import {Quection} from "./Quection/Quection";
import styles from '../../page/About Us/AboutUs.module.css';
import {useEffect} from "react";
import AOS from "aos";
import {Link} from "react-router-dom";
/*AOS*/

export function AboutUs() {
    useEffect(() => {
        AOS.init({
                offset: 100, // offset (in px) from the original trigger point
                delay: 100, // values from 0 to 3000, with step 50ms
                duration: 1000, // values from 0 to 3000, with step 50ms
                easing: 'ease-in-out', // default easing for AOS animations
                once: false, // whether animation should happen only once - while scrolling down
                mirror: true, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom',
            }

        );
        AOS.refresh()

    }, []);
    /*end of AOS*/

    return (
        <section className='font-poppins'>
            {/* start with About section*/}
            <section className='font-poppins'>
                <div className='flex flex-col gap-1'>
                    <div className={styles.bg}
                         style={{backgroundImage: `url(${Design})`}}>
                        <div
                            className={styles.mainTitleMainDiv}>

                            <h1 className={styles.bgTitle}>
                                About Us
                            </h1>
                        </div>

                    </div>
                    {/*About us*/}
                    <div className={styles.aboutUsFirstDiv} >
                        {/*paragraph*/}
                        <div className={styles.photographFirstDiv} >
                            <div className={styles.photographSecondDiv}>
                                <h1 className={styles.photographH1}  >
                                    About Us
                                </h1>
                                <p className={styles.photographPara} >
                                    <span className='text-[#FF9B26]'>We don't just talk results</span> , we deliver them.
                                    At loopy.lk, we're data-driven and results-oriented, ensuring your
                                    marketing efforts translate to real business growth
                                </p>
                                <button data-aos="fade-up"
                                    className={styles.photographButton} >

                                    <Link to="/contact"> Join Us</Link>
                                    <FaArrowRight className='mt-1'/>
                                </button>
                            </div>

                        </div>
                        {/*image*/}
                        <div className={styles.photographImages} >
                            {/*<div className='w-4/5 h-4/5 bg-[#F2F2F2] rounded '>

                            </div>*/}
                            <img src={loopy1} alt='' className='h-[300px] w-[300px]  xl:w-[250] top-0 right-4 rounded ' data-aos="fade-up"/>
                            <img src={loopy2} alt='' className='h-[300px] w-[300px] xl:w-[250] bottom-0 left-4 rounded ' data-aos="fade-right"/>
                        </div>
                    </div>
                </div>

            </section>
            {/*end of About section*/}
            {/* start feature*/}
            <section className={styles.featureFirstDiv}>
                <div className={styles.featureSecondDiv}>
                    {/*image*/}
                    <div className={styles.featureThirdDiv}>
                        <img src={feature} alt='' className='h-[300px] w-[400px]  bottom-0 left-24 rounded ml-5 mr-5 xl:w-[644px] xl:h-[490px]' data-aos="fade-right"/>
                    </div>
                    {/*paragraph*/}

                    <div className={styles.paragraphFirstDiv}>
                        <div className={styles.paragraphSecondDiv}>
                            <h2 className={styles.paragraphH1}>GO Digital</h2>
                            <p className={styles.paragraphParagraph}  data-aos="fade-up"
                               data-aos-anchor-placement="bottom-bottom">
                                Feeling left behind in the digital age?
                                <span className='text-[#E89A01]'>loopy.lk's
                                Go Digital Project</span> bridges the gap for local businesses like yours.
                            </p>
                            <p className={[styles.paragraphParagraph].join(' ')}  data-aos="fade-up"
                               data-aos-anchor-placement="bottom-bottom">
                                We
                                empower cafes, boutiques, and bustling enterprises to reach wider
                                audiences, ignite sales engagement, and build lasting brand
                                awareness.
                            </p>
                            <p className={[styles.paragraphParagraph, styles.paragraphSUb].join(' ')}  data-aos="fade-up"
                               data-aos-anchor-placement="bottom-bottom">
                                Imagine a thriving online presence that connects you with new
                                customers, fosters brand loyalty, and fuels your business growth. The
                                Go Digital Project makes it a reality. Join us and unlock the boundless
                                possibilities of digital marketing!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/*end of feature*/}

            {/*start of Assepect seo*/}
            <section className={styles.acceptCeoMain}>
                <div className={styles.acceptCeoSecondDiv}>
                    <div className={styles.acceptCeoThirdDiv}>
                        <h1 className={styles.acceptCeoH1}>Functional Aspect of SEO</h1>
                        <p className={styles.acceptCeoPara}>
                            Search Engine
                            Optimization (SEO) is in our DNA. Our team of SEO specialists are
                            obsessed with one thing: propelling your website to the top of
                            search results. We use proven strategies and the latest SEO best
                            practices to ensure your brand is seen by the right people at the
                            right time. Think increased website traffic, qualified leads, and
                            ultimately, more business for you.
                        </p>
                    </div>
                </div>

                {/*image and pharaghrtph*/}
                <div className={styles.imagePhotographyMainDiv}>
                    <div className={styles.imagePhotographySecond}>
                        <div className='lg:w-50% xl:w-[46rem]'>
                            <img src={asp1} alt=''
                                 className='mt-[-40px] w-full h-full lg:w-[510px] lg:h-[500px] xl:w-[600px] xl:h-full xl:mt-[40px]' data-aos="zoom-in-up"/>
                        </div>
                        <div className={styles.imagePhotographyTheird}>
                            <div className={styles.imagePhotographyFourth}>
                                <h1 className={styles.imagePhotographyH1} data-aos="zoom-in-up">Marketing And Branding</h1>
                                <p className={styles.imagePhotographyPara } data-aos="zoom-in-up" >
                                    Feeling lost in a sea of
                                    sameness? loopy.lk ignites brands and propels them to new heights.
                                    We're not just about marketing campaigns; we're about crafting
                                    powerful brand stories that resonate with your audience and drive real
                                    results.
                                    We don't just create marketing materials, we create experiences. Our
                                    team of passionate experts will delve into your brand's DNA,
                                    uncovering its unique story and translating it into captivating visuals,
                                    compelling messaging, and targeted campaigns. From social media
                                    magic to website wonderlands, we'll develop a strategy that reaches
                                    your ideal customers and fuels their loyalty.
                                    Join loopy.lk and watch your brand take flight! Let's work together to
                                    turn your vision into reality and unlock the full potential of your
                                    business.

                                </p>
                            </div>
                        </div>

                    </div>
                    <div className={styles.creativDesigningOne}>
                        <div className={styles.creativDesigningTwo}>
                            <div className={styles.creativDesigningThree}>
                                <h1 className={styles.creativDesigningH1} data-aos="zoom-in-up">Creative Designing</h1>
                                <p className={styles.creativDesigningPara} data-aos="zoom-in-up">
                                    Imagine your brand story brought to life, not just
                                    seen. loopy.lk goes beyond basic design. We're your creative partners,
                                    transforming your ideas into visually stunning experiences that
                                    captivate audiences and drive results. We don't just craft logos and
                                    websites, we design powerful marketing tools that tell your unique
                                    story and ignite engagement. From eye-catching visuals that grab
                                    attention to strategic layouts that guide user journeys, loopy.lk ignites
                                    your brand with creative fire. Let's turn your vision into a reality that
                                    resonates and connects.
                                </p>
                            </div>
                        </div>
                        <div className='mt-[45px] lg:w-[50%]'>
                            <img src={asp3} alt='' className='mt-[-40px] w-full h-full lg:w-[555px] xl:h-full' data-aos="zoom-in-up"/>
                        </div>


                    </div>
                    <div className={styles.multimediaOne}>
                        <div className='mt-[45px] lg:w-[50%]'>
                            <img src={asp2} alt='' className='lg:mt-[42px] w-full ' data-aos="zoom-in-up"/>
                        </div>
                        <div className={styles.multimediaTwo}>
                            <div className=' flex  gap-2 flex-col lg:w-[720px]'>
                                <h1 className='text-4xl font-bold text-center text-[30px] lg:ml-[504px]' data-aos="zoom-in-up">Multimedia</h1>
                                <p className='text-[1rem]  text-justify ml-[28px] mr-[28px] font-normal leading-10' data-aos="zoom-in-up">
                                    Tired of flat visuals and forgettable
                                    messages? loopy.lk injects your brand story with the electrifying
                                    energy of multimedia marketing. We create immersive experiences
                                    that seamlessly blend stunning visuals, captivating videos, and
                                    impactful audio to engage your audience on a whole new level.
                                    A symphony of visuals, sound, and motion that truly connects with
                                    your audience emotionally. loopy.lk goes beyond just creating
                                    content; we craft multifaceted experiences that boost brand
                                    engagement, drive results, and leave a lasting impression. Let's break
                                    through the noise with a multimedia marketing strategy that ignites
                                    your brand.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className={styles.webMainDiv}>
                        <div className={styles.webSecondDiv}>
                            <div className={styles.webThierdDiv}>
                                <h1 className={styles.webH1} data-aos="zoom-in-up">Web Designing</h1>
                                <p className={styles.webPara} data-aos="zoom-in-up">
                                    Your website is your online storefront -
                                    make it a masterpiece! loopy.lk designs stunning, user-friendly
                                    websites that not only look amazing but also drive results. We don't
                                    just build websites, we craft powerful marketing tools that convert
                                    visitors into loyal customers.
                                    We don't just build websites, we build partnerships. We'll work closely
                                    with you to understand your unique needs and goals, and then craft a
                                    website that's tailored to achieve them. Let loopy.lk transform your
                                    online presence and unlock the full potential of your digital storefront!
                                </p>
                            </div>
                        </div>
                        <div className=' '>
                            <img src={asp4} alt='' className='mt-[-40px] w-[500px] h-[268px] lg:ml-[120px] xl:ml-[14px] xl:h-full md:h-full' data-aos="zoom-in-up"/>
                        </div>


                    </div>


                </div>
            </section>
            {/*end of Assepect seo*/}

            {/*start of customer quection*/}
            <section>
                <div className='flex mt-50px'>
                    <div className='flex flex-col'>
                        <div className=' flex flex-col mt-[50px]'>
                            <div className='flex justify-center items-center'>
                                <img src={ask1} alt='' className='ml-[0px] lg:ml-[150px]' data-aos="zoom-in-up"/>

                            </div>
                        </div>
                        <div className='text-right '>
                            <div className=' flex  gap-2 flex-col mt-8'>
                                <h1 className='text-4xl font-bold text-center text-[30px] lg:ml-[210px]' data-aos="zoom-in-up">Commonly asked
                                    Questions</h1>
                                <p className='text-1xl  text-justify ml-[21px] mr-[21px] font-normal leading-8 lg:ml-[88px] lg:w-[1000px] xl:w-[1330px] ' data-aos="zoom-in-up">
                                    Unleashing Your Marketing Potential - Got Questions? We've Got
                                    Answers!
                                    At loopy.lk, we're passionate about empowering businesses with
                                    effective marketing solutions. We understand that navigating the
                                    marketing landscape can raise questions. Here, we answer some of our
                                    most frequently asked questions:
                                </p>

                                <div className='flex flex-col gap-2 mt-2  ml-[14px] mr-[14px] lg:ml-[88px] h-full' data-aos="zoom-in-up">
                                    <Quection/>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>

            </section>
            {/*End of customer quection*/}


        </section>
    );
}
