import Design from "../../../image/Loopy web/webdesign.jpg";
import web1 from "../../../image/Loopy web/webMain01.png";
import asp2 from "../../../image/Loopy web/Web1Remove.png";
import asp3 from "../../../image/Loopy web/Web2Remove.png";
import asp4 from "../../../image/Loopy web/Web3Remove.png";
import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

export function WebSite() {
    useEffect(() => {
        AOS.init({
                offset: 100, // offset (in px) from the original trigger point
                delay: 100, // values from 0 to 3000, with step 50ms
                duration: 1000, // values from 0 to 3000, with step 50ms
                easing: 'ease-in-out', // default easing for AOS animations
                once: false, // whether animation should happen only once - while scrolling down
                mirror: true, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom',
            }

        );
        AOS.refresh()

    }, []);

      /*  // Calculate offset dynamically based on scroll position
        const calculateOffset = () => {
            const elements = document.querySelectorAll('[data-aos]');
            elements.forEach(element => {
                const top = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;
                const offset = (windowHeight - top) / 2;
                element.setAttribute('data-aos-offset', offset.toString());
            });
        };

        calculateOffset();

        // Event listener for scroll to recalculate offset
        window.addEventListener('scroll', calculateOffset);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('scroll', calculateOffset);
        };*/
/*
    }, []);
*/


    return (
        <section className='font-poppins'>
            {/*start of main section*/}
            <section>
                <div className="bg-cover bg-center w-full h-[300px] md:h-[369px] lg:h-[500px]"
                     style={{backgroundImage: `url(${Design})`}}>
                    <div className='absolute top-[145px] left-0 right-0 transform -translate-y-1/2  lg:top-[220px]'>

                        <h1 className='flex font-bold text-center  text-[50px] md:text-[119px] text-white justify-center items-center'>
                            Website
                        </h1>
                    </div>

                </div>
            </section>


            {/*end of main section*/}

            <section className='mt-10'>
                {/*main div*/}
                <div className='flex flex-col mr-4 ml-4 md:mr-12 md:ml-12  lg:gap-8 gap-[6px]'>
                    <div  className='flex flex-col justify-center items-center '>
                        <h2 className='text-title text-[12px] md:text-[15px] lg:text-[18px]  font-bold'>MANAGE YOUR BUSINESS</h2>
                        {/*description*/}
                        <h1 className='text-subTitle text-[18px] md:text-[26px] lg-text-[32px] xl:text-4xl font-extrabold'>Select your web design hear</h1>
                        <p className=' text-[1rem] leading-6 text-center md:text-center mt-2  lg:w-[649px] xl:w-8/12 lg:mt-5' data-aos="fade-up"
                           data-aos-anchor-placement="top-center">
                            Explore our carefully chosen collection of web designs to find the perfect match for your online image.
                            Whether you prefer sleek, modern looks or classic, timeless styles, we have options to meet your needs.
                            Select from an assortment of professionally designed options to enhance your website and engage your audience.
                        </p>
                    </div>
                    {/*photo*/}
                    <div  className='flex gap-6 items-center justify-center'>

                        <img src={web1} alt='' className='w-[275px] md:w-[50%] mt-3  xl:mt-6' data-aos="fade-down"
                             data-aos-easing="linear"
                             data-aos-duration="1500"/>


                    </div>

                </div>
            </section>
            <section className='lg:mt-section_Space mt-14'>
                <div className='mr-4 ml-4 md:mr-12 md:ml-12'>
                    <div className='flex flex-col w-full gap-[50px]   lg:gap-32'>
                        {/*first desc*/}
                        <div className='flex w-full flex-col gap-4 lg:gap-0 lg:flex-row '>
                            <div   className='text-left   lg:w-1/2'>
                                <div className='lg:w-10/12 flex  justify-center  gap-2    lg:gap-4 flex-col  xl:w-full xl:mt-6  '>
                                    <h1 className='text-subTitle text-[18px] lg:text-4xl font-extrabold md:text-[26px] text-center lg:text-left'>General websites</h1>
                                    <p className='text-[1rem] leading-6 text-justify md:text-center lg:leading-10 lg:text-left' data-aos="fade-up"
                                       data-aos-anchor-placement="top-center">
                                        In today's digital age, your website is
                                        more than just an online brochure; it's the cornerstone of your brand's
                                        online presence. A website that doesn't just look good, it works
                                        wonders for your business. At loopy.lk, we don't build websites, we
                                        craft dynamic digital hubs that captivate audiences, drive
                                        engagement, and ultimately, fuel your growth.
                                        Don't settle for a static website. Let loopy.lk craft a dynamic digital
                                        experience that becomes the cornerstone of your online success.
                                    </p>
                                </div>
                            </div>

                            <div  className=' lg:w-1/2 lg:flex flex md:items-center md:justify-center '>
                                <img src={asp3} alt='' className='w-[100%] md:w-[411px] lg:w-[100%] xl:w-[550px] xl:mt-[-132px]' data-aos="fade-down"
                                     data-aos-easing="linear"
                                     data-aos-duration="900"/>
                            </div>


                        </div>
                        {/*second desc*/}
                        <div className='flex w-full flex-col-reverse gap-4 lg:gap-0 lg:flex-row '>
                            {/*photo*/}
                            <div  className='lg:w-1/2 lg:flex flex md:items-center md:justify-center'>
                                <img src={asp2} alt='' className='w-[97%] md:w-[411px] lg:[97%] xl:w-[584px] xl:mt-[-238px]' data-aos="zoom-in-left"/>
                            </div>

                            {/*paragraph*/}
                            <div  className='text-right   lg:w-1/2 '>
                                <div className='lg:w-10/12 flex  justify-center  gap-2    lg:gap-4 flex-col xl:w-full '>
                                    <h1 className='text-subTitle text-[18px] lg:text-4xl font-extrabold md:text-[26px] text-center lg:text-right'>Educational websites</h1>
                                    <p className='text-[1rem] leading-6 text-justify md:text-center lg:leading-10 lg:text-right' data-aos="fade-up"
                                       data-aos-anchor-placement="top-center">
                                        Education shouldn't be a
                                        snoozefest! loopy.lk designs dynamic educational websites that ignite
                                        curiosity, inspire learning, and make knowledge retention a joy. We go
                                        beyond static textbooks, crafting engaging experiences that cater to
                                        different learning styles and ages.
                                        loopy.lk: We're passionate about making education accessible and
                                        exciting! Let us help you create an educational website that fosters a
                                        love for learning and empowers learners of all ages to unlock their full
                                        potential.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className='flex w-full flex-col gap-4 lg:gap-0 lg:flex-row    '>
                            <div  className='text-left   lg:w-1/2'>
                                <div className='lg:w-10/12 flex  justify-center  gap-2 lg:mt-2    lg:gap-4 flex-col xl:w-full xl:mt-6 '>
                                    <h1 className='text-subTitle text-[18px] lg:text-4xl font-extrabold md:text-[26px] text-center lg:text-left'>E-commerce websites</h1>
                                    <p className='text-[1rem] leading-6 text-justify md:text-center lg:leading-10 lg:text-left' data-aos="fade-up"
                                       data-aos-anchor-placement="top-center">
                                        Your online store deserves to be a
                                        shopping haven! loopy.lk designs stunning, user-friendly e-commerce
                                        websites that not only showcase your products beautifully but also
                                        drive sales and maximize your online revenue potential.
                                        Don't just list products, create a shopping experience! loopy.lk goes
                                        beyond building an e-commerce website; we craft online stores that
                                        are visually appealing, user-friendly, and conversion-focused. We'll
                                        also work with you to incorporate e-commerce marketing strategies to
                                        drive traffic to your online store and boost your sales. This may
                                        include search engine optimization (SEO), social media marketing, and
                                        paid advertising campaigns.loopy.lk: Your partner in building a thriving online presence. Let us
                                        unlock the full potential of your e-commerce website and turn
                                        browsers into happy, loyal customers!
                                    </p>
                                </div>
                            </div>
                            <div   className=' lg:w-1/2 lg:flex flex md:items-center md:justify-center'>
                                <img src={asp4} alt='' className='w-[100%] h-[95%] md:w-[411px] lg:w-[100%] xl:w-[508px] xl:h-[514px] xl:mt-[-97px]' data-aos="fade-up"
                                     data-aos-anchor-placement="center-center"/>
                            </div>


                        </div>



                    </div>

                </div>
            </section>
        </section>
    );
}
