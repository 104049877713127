import {Route, Routes} from "react-router-dom";
import {Home} from "../../page/Home/Home";
import {AboutUs} from "../../page/About Us/AboutUs";
import {Designing} from "../../page/Designing/Designing";
import {Photography} from "../../page/Photograpy/Photography";
import {WebSite} from "../../page/WebSite/WebSite";
import Contact from "../../page/ContactUs/Contact";
import {GetInTouch} from "../../page/GetInTouch/GetInTouch";
import {SocialMedia} from "../../page/SocialMedia/SocialMedia";

export function MainContext() {
    return (
        <div className="">

            <Routes>
                <Route path="/" Component={Home}></Route>
                <Route path="/aboutUs" Component={AboutUs}></Route>
                <Route path="/designing" Component={Designing}></Route>
                <Route path="/photography" Component={Photography}></Route>
                <Route path="/website" Component={WebSite}></Route>
                <Route path="/contact" Component={Contact}></Route>
                <Route path="/touch" Component={GetInTouch}></Route>
                <Route path="/social" Component={SocialMedia}></Route>
            </Routes>
        </div>    );
}
