import Design from '../../../image/Loopy web/BackgroundNew.jpg'
import logo from '../../../image/Loopy web/LogoNew.png'
import './Home.module.css'
import section3 from '../../../image/Loopy web/marketing1.jpg'
import {BsBoundingBox} from "react-icons/bs";
import {RiSlideshowLine} from "react-icons/ri";
import {LiaHourglassStartSolid} from "react-icons/lia";
import education from '../../../image/Loopy web/Education.jpg'
import ecommerce from '../../../image/Loopy web/Ecommerce_6.max-1280x960.png'
import general from '../../../image/Loopy web/9-General-Ecommerce.png'
import birth1 from '../../../image/Loopy web/p2.jpg'
import birth2 from '../../../image/Loopy web/p6.jpg'
import birth5 from '../../../image/Loopy web/ar2.jpg'
import birth6 from '../../../image/Loopy web/p5.jpg'
import {SwipperBrand} from "./Swipper/SwipperBrand";
import {useEffect, useState} from "react";
import {SwipperComment} from "./Swipper/SwipperComment";
import {SwipperNews} from "./Swipper/SwipperNews";
import style from "../Home/Home.module.css"
import Contact from "../ContactUs/Contact";
import {Link} from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaPalette } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa";
import { FaCamera } from "react-icons/fa";
import { FaBullhorn } from "react-icons/fa6";
import { FaBehance } from "react-icons/fa6";

import AOS from "aos";

export function Home() {
    /*design video*/
    const src = "https://www.youtube.com/embed/V_cioLTaDvQ?si=11rvRDAdXh3eFrWK";
    /*multimedia*/
    const src1 = "https://youtu.be/In0dAUxq_Lk?t=33";
    /*slide*/
    const [currentSlide, setCurrentSlide] = useState(0);

    // start slides content
    const slides = [
        {
            id: '1',
            title: 'We bring',
            subtitle: 'Customers',
            content: 'Vel officiis dolor ea illo aut eligendi ullam non laudantium magnam et recusandae molestiae sit iure unde aut voluptate quaerat.'
        },
        {
            id: '2',
            title: 'We give',
            subtitle: 'Solution',
            content: 'Vel officiis dolor ea illo aut eligendi ullam non laudantium magnam et recusandae molestiae sit iure unde aut voluptate quaerat.'
        },
        {
            id: '2',
            title: 'We proud',
            subtitle: 'Company',
            content: 'Vel officiis dolor ea illo aut eligendi ullam non laudantium magnam et recusandae molestiae sit iure unde aut voluptate quaerat.'
        },

        // Add more slides as needed
    ];
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 3000); // Change slide every 3 seconds (3000 milliseconds)

        return () => clearInterval(interval);
    }, [slides.length]);
    // End slides content

    /*AOS*/
    useEffect(() => {
        AOS.init({
                offset: 100, // offset (in px) from the original trigger point
                delay: 100, // values from 0 to 3000, with step 50ms
                duration: 1000, // values from 0 to 3000, with step 50ms
                easing: 'ease-in-out', // default easing for AOS animations
                once: false, // whether animation should happen only once - while scrolling down
                mirror: true, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom',
            }

        );
        AOS.refresh()

    }, []);
    /*end of AOS*/

    return (
        /*main section*/
        <section className='font-poppins'>
            {/* start with home section*/}
            <section className='font-poppins'>
                <div className='flex'>
                    <div className={style.heroBackground} style={{backgroundImage: `url(${Design})`}}>
                        <div className='absolute top-10 left-10  '>
                            {/*slide*/}
                            <div>
                                {slides.map((slide, index) => (
                                    <div key={slide.id} className={index === currentSlide ? "block" : "hidden"}>
                                        <div className="flex flex-col  lg:gap-4 lg:mt-[158px] md:mt-[60px]">
                                            <h2 className="font-bold text-white lg:text-2xl lg:text-[6.5rem]">{slide.title}</h2>
                                            <span
                                                className="text-[#d90a2c] text-[28px] font-semibold lg:text-[63px]">{slide.subtitle}</span>
                                        </div>
                                        <p className="text-white w-[190px] text-[12px]  lg:w-2/6 lg:mt-5 lg:text-[18px]">{slide.content}</p>
                                        <button className={style.action}>
                                            View More
                                        </button>
                                    </div>
                                ))}
                            </div>

                        </div>
                        {/* This div acts as a container for the logo */}
                        <div
                            className="absolute bottom-[230px] left-1/2 transform -translate-x-1/2 lg:w-[232px] lg:mb-[-284px] mb-{255px}">
                            <img src={logo} alt='' className=" h-full mb-[24px] md:w-[115px] xl:mb-[52px] xl:w-[218px] w-[108px]"/>
                        </div>
                    </div>
                </div>
                {/*after logo pharaghraph*/}
                <div className={style.heroParaMainDiv}>
                    <div className={style.heroParaSubDiv}>
                        <p className={style.heroPara} data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                            Loopy Holdings is a collection of all kinds of modern and trendiest services and solutions.
                            Where one can get all of his or her media requirements fulfilled far beyond expectations.
                            Branding and advertising, media coverage, web designing, social media maintenance, etc.
                            Loopy Holdings cover all digitalized services and consultancies one needs in every aspect.
                        </p>
                        {/*Social Media Button List*/}
                        <div className='flex justify-center mt-[15px] gap-1'>
                            <a href="https://www.facebook.com/share/1vAhvxdGkW8nSMdT/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer"
                               className='rounded-full bg-[#d90a2c] text-white justify-center flex w-[50px] h-[50px] items-center'
                               data-aos="zoom-out-down">
                                <FaFacebookF/>
                            </a>
                            <a href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A99144645&keywords=loopy%20holdings&origin=RICH_QUERY_SUGGESTION&position=0&searchId=d7e2afb3-0de5-4218-a476-09402dff2c12&sid=0hF&spellCorrectionEnabled=false" target="_blank" rel="noopener noreferrer"
                               className='rounded-full bg-[#d90a2c] text-white justify-center flex w-[50px] h-[50px] items-center'
                               data-aos="zoom-out-down">
                                <FaLinkedinIn/>
                            </a>
                            <a href="https://www.tiktok.com/@loopy.lk?_t=8kh6QXhp1ph&_r=1" target="_blank" rel="noopener noreferrer"
                               className='rounded-full bg-[#d90a2c] text-white justify-center flex w-[50px] h-[50px] items-center'
                               data-aos="zoom-out-down">
                                <FaTiktok/>
                            </a>
                            <a href="https://www.instagram.com/loopy.lk_?igsh=MWRneWQ3ZjM3OW1odQ==" target="_blank" rel="noopener noreferrer"
                               className='rounded-full bg-[#d90a2c] text-white justify-center flex w-[50px] h-[50px] items-center'
                               data-aos="zoom-out-down">
                                <FaInstagramSquare/>
                            </a>
                            <a href="https://www.behance.net/loopyholdings" target="_blank" rel="noopener noreferrer"
                               className='rounded-full bg-[#d90a2c] text-white justify-center flex w-[50px] h-[50px] items-center'
                               data-aos="zoom-out-down">
                                <FaBehance />

                            </a>
                        </div>
                    </div>
                </div>


            </section>
            {/*end of home section*/}

            {/*start with  category*/}
            <section className='mt-12'>
                {/*
                title and subTitle
*/}

                <div className={style.categoryMainDiv}>
                    {/*<h2 className={style.categoryMainDivTitle}>category</h2>*/}
                    <h1 className={style.categoryMainDivSubTitle}>We Offer Best Services</h1>
                </div>

                <div className={style.categorySubDiv}>
                    {/*1*/}
                    <div className={[style.categorySubDiv1, style.categorySubSud].join(' ')} data-aos="zoom-in-up">
                        {/*img*/}
                        <div className={style.categorySubDiv1Img}>
                            {/*<img src={marketing} alt='' className='h-[70px] lg:mt-[34px]'/>*/}
                            <div
                                className='rounded-full text-[#d90a2c] justify-center flex  items-center'>
                                <FaPalette className="size-20"/>
                            </div>

                        </div>
                        {/*paragraph*/}
                        <div className={style.categorySubDiv1Para}>
                            <h1 className={style.categorySubDiv1ParaH1}>

                                Creative Designing
                            </h1>
                            <p className={style.categorySubDiv1ParaPara}>
                                We assist in the creation of brands that are more than just visual identities; we assist
                                in the creation of brands that people notice.
                            </p>
                        </div>


                    </div>
                    {/*2*/}
                    <div className={style.categorySubDiv2} data-aos="zoom-in-up">
                        {/*first*/}
                        <div className={style.categorySubDiv2FirstDiv}/>
                        {/*second*/}
                        <div className={style.categorySubDiv2SecondDiv}
                            /*style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px', zIndex: 1}}*/>
                            {/*img*/}
                            <div
                                className='rounded-full text-[#d90a2c] justify-center flex  items-center'>
                                <FaLaptopCode className="size-24"/>
                            </div>


                            {/*para*/}
                            <div className={style.categorySubDiv2SecondDivPara}>
                                <h1 className={style.categorySubDiv2SecondDivParaH1}>

                                    Web Designing
                                </h1>
                                <p className={style.categorySubDiv2SecondDivParaPara}>
                                    If you're seeking for a unique concept, something simple yet irresistible and
                                    imaginative? The Loopy team will bring your thougths into life.
                                </p>
                            </div>
                        </div>

                    </div>
                    {/*3*/}
                    <div className={style.categorySubDiv1} data-aos="zoom-in-up">
                        {/*img*/}
                        <div className={style.categorySubDiv1Img}>
                            <div
                                className='rounded-full text-[#d90a2c] justify-center flex  items-center'>
                                <FaCamera className="size-20"/>
                            </div>

                        </div>

                        <div className={style.categorySubDiv1Para}>
                            <h1 className={style.categorySubDiv1ParaH1}>
                                Multimedia
                            </h1>
                            <p className={style.categorySubDiv1ParaPara}>
                                Mobile marketing, live casting, photo, video, film creation and much more a. Develop your brand recognition in a unique and effective way with Loopy.
                            </p>
                        </div>


                    </div>
                    {/*4*/}
                    <div className={style.categorySubDiv4} data-aos="zoom-in-up">
                        <div className={style.categorySubDiv2SecondDiv}
                            /* style={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px', zIndex: 1}}*/>

                            {/*img*/}
                            <div className={style.categorySubDiv2SecondDivImg}>
                                {/* <div className='absolute bg-[#FFF1DA] w-16 h-16 rounded-br-2xl rounded-tl-2xl flex items-center justify-center top-20vw right-20vw'>
                                 Content inside the background div (if any)
                            </div>
                            <img src={marketing} alt='' className='h-[78px] absolute top-50vh' />*/}
                                <div
                                    className='rounded-full text-[#d90a2c] justify-center flex  items-center'>
                                    <FaBullhorn className="size-20"/>
                                </div>


                            </div>

                            <div className={style.categorySubDiv4SecondDivPara}>
                                <h1 className={style.categorySubDiv2SecondDivParaH1}>
                                    Marketing and Branding
                                </h1>
                                <p className={style.categorySubDiv2SecondDivParaPara}>
                                    A website boosts the credibility of your company. Stand out from competitors by having a responsive and appealing website.
                                </p>
                            </div>

                        </div>
                    </div>


                </div>
            </section>
            {/*End with  category*/}

            {/*start with Marketing*/}
            <section className='mt-section_Space'>
                <div className={style.marketingMainDiv}>
                    {/*img*/}
                    <div className={style.marketingMainDivImg}>

                        <div className={style.marketingMainDivSubImg}>
                            <img src={section3} alt='' className='w-[550px] md:mt-[-80px] md:w-[776px] mt-[5px] rounded-2xl' data-aos="zoom-in-left "/>
                        </div>

                    </div>
                    {/*content*/}
                    <div className='flex w-full '>
                        {/*mainContent sub div*/}
                        <div className={style.marketingSubDiv} data-aos="zoom-in-right">
                            <div className={style.marketingSubDivMain}>
                                <div className={style.marketingSubDivMainH2Div}>
                                    <h2 className={[ style.providingAllH1,style.creativeTitleNew].join(' ')}>MARKETING AND
                                        BRANDING</h2>
                                    <div className='flex flex-col text-cente lg:mr-[50px] xl:mr-[247px]'>
                                        <h1 className={style.creativeSubTitle}>Providing all sorts of </h1>
                                        <h1 className={style.creativeSubTitle1}>marketing solutions </h1>
                                    </div>

                                </div>
                                <div className='flex lg:mt-2' >
                                    <p className='text-[13px] text-justify  xl:mt-[20px] xl:text-[16px]  md:items-center md:text-center md:mt-[30px] xl:text-start mt-[20px]'> Uplifting the business to the peak point
                                        in the market using the latest strategies and techniques through various kinds
                                        of campaigns and launching.<br/>
                                        </p>
                                </div>

                                <button className={style.marketingSubDivMainButton} >
                                    <Link to="/designing">View More</Link>
                                </button>
                            </div>
                        </div>
                    </div>


                </div>

            </section>
            {/*end with Marketing*/}

            {/*creative design */}
            <section className=' mt-section_Space'>
                <div className={style.creativeMainDiv} >
                    <div className={style.creativeSubDiv}>
                        {/*content*/}
                        <div className={style.creativeContainerDiv} data-aos="zoom-in-right">
                            <h2 className={style.creativTiteleNew1}>CREATIVE DESIGNING </h2>
                            <div className='flex  flex-col xl:text-start xl:mt-2'>
                                <h1 className={style.creativeSubTitle2}>Providing all shots of </h1>
                                <h1 className={style.creativeSubTitle3}>designing solution </h1>
                                {/*content*/}
                                <div className='flex flex-col gap-4 xl:ml-[135px] mt-1 xl:justify-center  justify-center xl:mt-3 md:ml-[47px] md:w-[557px]'>
                                    <div className='flex gap-4'>
                                        {/*icon*/}
                                        <div className={`${style.creativeIcon} bg-amber-400`}>
                                            <BsBoundingBox className='text-white text-center size-6'/>
                                        </div>
                                        <div className={style.creativeContainer}>
                                            <h5 className={style.creativeContainerH5}>Meet the loopy.lk Crew</h5>
                                            <p className={style.creativeContainerP}>
                                                We're Not Your Average
                                                Marketing Robots
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex gap-4'>
                                        {/*icon*/}
                                        <div className={`${style.creativeIcon} bg-red-500`}>
                                            <RiSlideshowLine className='text-white text-center size-6'/>
                                        </div>
                                        <div className={style.creativeContainer}>
                                            <h5 className={style.creativeContainerH5}>Discuss about designing</h5>
                                            <p className={style.creativeContainerP}>
                                                Design Doesn't Have To Be Confusing.
                                                Let's Break it Down Together.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex gap-4 '>
                                        {/*icon*/}
                                        <div
                                            className={`${style.creativeIcon} bg-blue-950`}>
                                            <LiaHourglassStartSolid className='text-white text-center size-6'/>
                                        </div>
                                        <div className={style.creativeContainer}>
                                            <h5 className={style.creativeContainerH5}>Let’s start</h5>
                                            <p className={style.creativeContainerP}>
                                                Let's Start Turning Heads with Eye-Catching Designs.
                                            </p>
                                        </div>
                                    </div>
                                    {/*button*/}
                                    <button
                                        className='flex w-2/6 bg-orange h-10 text-center justify-center items-center text-white rounded mt-5 mb-10  xl:ml-[-2px]  md:ml-[177px] '>
                                        <Link to="/designing">View More</Link>
                                    </button>

                                </div>
                            </div>

                        </div>
                        {/*video*/}
                        <div className='flex justify-center items-center lg:w-9/12 '>
                            <iframe width="560" height="315"
                                    className="w-[296px] md:w-[700px] xl:h-[538px]"
                                    src="https://www.youtube.com/embed/of_kBPXMyPU?si=TeD8bFIwVv2yRyOC"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen></iframe>


                        </div>

                    </div>
                </div>
            </section>

            {/*multiMedia*/}
            <section className='mt-section_Space'>
                <div className='flex flex-col ml-2 mr-2  gap-6 lg:flex lg:flex-row xl:mb-[66px]'>
                    {/*start content*/}
                    <div className={style.multiMediaMainDiv} >
                        <div className={style.multiMediaMainDivContent} >
                            <h2 className={[style.creativeTitle,style.multimediaSubNew].join(' ')}>MULTIMEDIA</h2>
                            <div className={style.multiMediaMainDivContentSubDiv} >
                                <h1 className={style.creativeSubTitle4}> Click, Play, Discover</h1>
                                <h1 className={style.creativeSubTitle5}> Immerse Yourself in Our Multimedia
                                    Experiences</h1>
                                <p className={style.multiMediaMainDivContentPara}>
                                    We Don't Just Take Photos, We Capture Brand
                                    Emotions
                                    Explore Our
                                    Fun & Creative Photoshoot Ideas.

                                </p>
                                <button
                                    className={style.marketingSubDivMainButton}>
                                    <Link to="/photography">View More</Link>
                                </button>
                            </div>
                        </div>
                        <div className={style.multiMediaMainDivImg}>
                            <div className='flex  '>
                                <img src={birth1} alt='' className='rounded-2xl w-[3000px] xl:h-[268px] h-[340px]'/>
                            </div>
                            <div className='flex  '>
                                <img src={birth6} alt='' className='rounded-2xl w-[3000px] xl:h-[268px] h-[340px]'/>

                            </div>
                            <div className='flex  '>
                                <img src={birth5} alt='' className='rounded-2xl w-[3000px] xl:h-[268px] h-[340px]'/>

                            </div>


                        </div>
                    </div>
                    {/*end of content*/}
                    {/*image*/}
                    <div className='flex justify-center items-center lg:w-9/12 lg:mr-[50px] '>

                        <img src={birth2} alt='' className=' w-[800px] sm:h-[301px]   xl:mr-[50px] rounded-2xl  md:mt-[-688px] md:h-[827px] xl:mt-[-29px] xl:h-[508px]'/>

                        {/*<img src={birth5} alt='' className='rounded w-[2700px] h-[600px]'/>*/}
                    </div>

                </div>
            </section>

            {/*web*/}
            <section className=' mt-section_Space'>
                <div className='w-full bg-[#F2F2F2] h-full'>
                    <div className='flex  w-full  '>

                        <div className={style.webMainDiv}>
                            <div className="bg-cover bg-center flex w-full md:h-[360px]"
                                 style={{backgroundImage: `url(${general})`}}>
                                <div className={style.webMainContainer}>
                                    <h2 className={style.webMainContainerTitle}>CREATIVE</h2>

                                    <h1 className={style.webMainContainerSubTitle}>E-commerce websites</h1>
                                    <button
                                        className={style.webMainContainerButton}>
                                        <Link to="/website">View More</Link>
                                    </button>

                                </div>
                            </div>
                            <div className="bg-cover bg-center flex w-full md:h-[360px] "
                                 style={{backgroundImage: `url(${ecommerce}) `}}>
                                <div className={style.webMainContainer}>
                                    <h2 className={style.webMainContainerTitle}>CREATIVE</h2>

                                    <h1 className={style.webMainContainerSubTitle}>General Websites</h1>
                                    <button
                                        className={style.webMainContainerButton}>
                                        <Link to="/website">View More</Link>
                                    </button>

                                </div>
                            </div>
                            <div className="bg-cover bg-center flex w-full md:h-[360px]"
                                 style={{backgroundImage: `url(${education}) `}}>
                                <div className={style.webMainContainer}>
                                    <h2 className={style.webMainContainerTitle}>CREATIVE</h2>

                                    <h1 className={style.webMainContainerSubTitle}>Educational Websites</h1>
                                    <button
                                        className={style.webMainContainerButton}>
                                        <Link to="/website">View More</Link>
                                    </button>

                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </section>

            {/*brandName*/}
            <section className='mt-section_Space' >
                <div className='flex flex-col gap-6'>
                    <div className='flex w-full items-center flex-col justify-center'>
                        <h2 className={style.categoryMainDivTitle}>BRAND</h2>

                        <h1 className={style.categoryMainDivSubTitle}>Brands We are Working</h1>
                    </div>
                    <SwipperBrand/>
                </div>


            </section>

            {/*comment*/}
            <section className='mt-section_Space bg-[#F2F2F2] ' >
                <div className='flex flex-col gap-4 '>
                    <div className='flex w-full items-center flex-col justify-center mt-6'>
                        <h2 className={style.categoryMainDivTitle}>CLIENT</h2>

                        <h1 className={style.categoryMainDivSubTitle}>See What Our Clients Say </h1>
                        <h1 className={style.categoryMainDivSubTitle}>About Us</h1>

                    </div>
                    <div className='mt-1 '>
                        <SwipperComment/>

                    </div>
                </div>
            </section>

            {/*news*/}
            <section className='mt-section_Space' >
                <div className='flex flex-col gap-4' data-aos="fade-up"
                     data-aos-anchor-placement="top-center">
                    <div className='flex w-full items-center flex-col justify-center'>
                        <h1 className={style.categoryMainDivSubTitle}>News Feed</h1>
                    </div >
                    <SwipperNews/>
                </div>
            </section>
            <Link to='/contact'>
                <section className='mt-section_Space'>
                    <Contact/>
                </section>
            </Link>

        </section>

    );
}
